import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import $ from "jquery";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import _ from "lodash";
import store from "../../Store/index";
import Header from "../Common/Header/Header";
import TopNav from "../Common/TopNav/TopNav";
import Attachments from "../Modals/Attachments/Attachments";
import Comments from "../Modals/Comments/Comments";
import Dropdown from "react-bootstrap/Dropdown";
import Activity from "../Modals/Activity/Activity";
import Changes from "../Modals/Changes/Changes";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Delete from "../Modals/Delete/Delete";
import Decline from "../Modals/Decline/Decline";
import InvoiceDetail from "../Modals/InvoiceDetail/InvoiceDetail";
import InvoiceMoreDetails from "../Modals/InvoiceMoreDetails/InvoiceMoreDetails";
import POLogs from "../Modals/POLogs/POLogs";
import Post from "../Modals/Post/Post";
import Import from "../Modals/Import/Import";
import Report from "../Modals/Report/Report";
import CheckApproval from "../Modals/CheckApproval/CheckApproval";

import moment from "moment";
import Move from "../Modals/Move/Move";
import * as SupplierActions from "../../Actions/SupplierActtions/SupplierActions";
import * as InvoiceActions from "../../Actions/InvoiceActions/InvoiceActions";
import * as ChartActions from "../../Actions/ChartActions/ChartActions";
import * as UserActions from "../../Actions/UserActions/UserActions";
import * as SetupActions from "../../Actions/SetupRequest/SetupAction";
import * as PaginationActions from "../../Actions/paginationActions/paginationActions";
import ReactPaginate from "react-paginate";
import ConfirmationModal from "../Modals/Confirmation/Confirmation";
import {
  handleAPIErr,
  zoomIn,
  zoomOut,
  handleDropdownZooming,
  downloadAttachments,
  getActivatedTab,
  isSafari,
  isValidString,
  isSuccessRequest,
  isFailedRequest,
  getToastMessage
} from "../../Utils/Helpers";

import { options } from "../../Constants/Constants";
import ActivityInvoice from "../Modals/ActivityInvoice/ActivityInvoice";
import { TransferRecords } from "../Modals/TransferRecords/TransferRecords";
import {
  downloadFileFromPresignedUrl,
  getBase64FromPreSignedS3Url,
  isRemoteStorage,
  uploadFileListToS3
} from "../../Utils/S3StorageHelper";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const uuidv1 = require("uuid/v1");

class Invoice extends Component {
  constructor(props) {
    super();
    this.state = {
      isLoading: false,
      tran: "", //tran of current selected invoice
      multipleTrans: [], //when user selects multiple Invoices to perform different functionality

      invoicesListSearch: props.pageData?.searchTerm ?? "", //search on invoices list
      getInvoicesList: [], //side menu (left side) invoices list data
      clonedGetInvoicesList: [], //a copy of  getInvoicesList
      invoiceTallies: [], //e.g Draft, Pending, Approved, etc
      count: 0,
      activeInvoice: "", //to add class active in lists of getting invoices (in left side )
      activeInvoiceTallis: "", //to add class active on invoices tallis
      showInvoiceTallisTabPane: "", //to add class active on invoices tallis below tab pane
      filteredInvoicesList: [], //this contains filterd list and used for searching on it
      viewTeam: "N",
      teamInvcCheck: "", //to check selected invoice is team invoice or not
      preview: "", //invoice PDF
      previewList: [], //list of base 64 PDFs
      numPages: null,
      pageNumber: 1,
      numPagesArr: [], //it contains number of pages of each PDF
      comments: [], //invoice comments
      attachments: [], //invoice attachments
      previewableAttachments: [],
      attachmentSize: 0, //default 0 Bytes,  attachments should always less than 29.5 MB
      approvers: [], //to just show on side menuw bar
      invoiceChanges: [],
      invoiceActivity: [],
      lines: [], //invoice lines
      subTotal: 0, //to just show on invoice-details page sub total
      poLines: [], //po lines
      advancedListEdit: [], //advancedList for editing in Invoice Edit Module
      advancedList: [],
      // exclude filter 'Zero', 'Close' and 'fully Approved'
      fullyApproved: false,

      approvalsGroups: [], //list of approvals Groups checkboxes to filter invoice list
      chartCodesList: [],
      clonedChartCodesList: [], //copy of chart codes lsit
      openAttachmentsModal: false,
      openCommentsModal: false,
      openActivityModal: false,
      openChangesModal: false,
      openDeclineModal: false,
      openInvoiceDetailModal: false,
      openPOLogsModal: false,
      openDeleteModa: false,
      openImportModal: false,
      openReportModal: false,
      openMoveModal: false,
      openPostModal: false,

      invoiceMoreDetails: "",
      openInvoiceMoreDetailsModal: false,

      sortFilterInvc: "supplierName",
      sortFilterCheckInvc: "ASC", //to check the sort is in ascending OR descending Order  Ascending -> ASC, Descending -> DESC
      // dropdown coding
      scaling: 3.4,
      dropdownZoomingValue: { label: "40%", value: "40%" },

      flags: [], //restructured flags according to select dropdown to just show in Line Items Modal ,comming from get api (tracking codes)
      clonedFlags: [], //a copy of flags
      basisOptions: [],
      toggleRightSidebar: true,
      rotate: 0,

      batchList: [],
      batchListOptions: [], //restructured batch listfor the drop-down when Move popup opens
      batchNo: "", //batch no of current selected batch

      bankDetails: {}, //supplier bank details
      currency: "", //supplier currency

      totalInvoices: "",
      pageStart: "",
      pageEnd: "",
      page: props.pageData?.pageNumber ?? 1, //The page to retrieve. Returns the first page if left as zero.
      resetPage: true,
      totalPages: "", //getNewPOList return total num of pages
      displayRecords: localStorage.getItem("orderDPR") || 10, //The number of orders to display.
      zero: false,
      close: false,
      InvoiceListSearch: "",
      sortFilter: "supplier",
      approved: "",
      supplierCode: "",
      invoiceNumber: "",
      requestedBy: "",
      description: "",
      dueDate: "",
      payDate: "",
      supplierName: "",
      approvalOptions: [],
      invoiceDate: "",
      supplierEmail: "",
      openCheckApprovalModal: false,
      approvalGroup: "",
      // move approver types
      typeMove: "",
      order: "Ascend",
      allSelected: false,
      guid: null,
      activatedPage: null,
      checked: false,
      selectedGuid: null,
      multipleSelectedGuid:[],
      supplierGuid: "",
      downloadAttachmentsOptions: {
        includeAll: true,
      },
      collapseIcon:{
        attachment:false,
        approvals:false,
        download:false,
        batchList:false,
        export:false,
        activity:false,
        changes:false
      },
      showTransferRecordsModal: false,
      selectedBatchNos:[],
      clonedBatchList:[],
      refreshedGuid:null,
      refreshedClicked:false,
      isSendForApproval:false,
      activatedTab: null,
      edited: false,
      isModalOpen: false,
      modalContent: {
        title: '',
        body: '',
        actionButtonName: '',
        onConfirm: () => {},
      },
      currentAttachmentIndex: 0,
      isBankDetailsCollapsed: true,
      bankOptionsFetchedFor: undefined,
      activiesFetchedFor: undefined,
    };
  }
  async componentDidMount() {
    //right hand side bar setting with PO Image
    window.addEventListener(
      "resize",
      () => {
        $(".mm_ordr1").addClass("mm_order_pdfMain");

        if ($(window).width() > 991) {
          this.setState({ toggleRightSidebar: true });
        }
        if ($(window).width() <= 991) {
          this.setState({ toggleRightSidebar: false });
        }
        if ($(window).width() >= 2045) {
          $(".invoice_pdf_new").addClass("invoice_pdf_new1");
        }
        if ($(window).width() < 2045) {
          $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
        }
      },
      false
    );
    // end
    //focus search input field by pressing Tab key
    document.onkeydown = function (evt) {
      evt = evt || window.event;
      if (evt.keyCode == 9) {
        evt.preventDefault();
        let id = document.getElementById("invoicesListSearchId");
        if (id) {
          document.getElementById("invoicesListSearchId").focus();
        }
      }
    };

    let { viewTeam, sortFilterInvc, sortFilterCheckInvc } = this.state;
    //Team Invoice Check
    viewTeam = localStorage.getItem("teamInvoice") || "N";
    //getting default sorting list setting from localstorage
    sortFilterInvc = localStorage.getItem("sortFilterInvc") || "supplierName";
    sortFilterCheckInvc = localStorage.getItem("sortFilterCheckInvc") || "ASC";
    this.setState({ viewTeam, sortFilterInvc, sortFilterCheckInvc });

    $(document).ready(function () {
      var vw = $(window).width();
      var nav = $(".navbar.fixed-top").height();
      var underNav = $(".order-tabs").height();
      var wh = $(window).height();
      var h = wh - nav - 60;
      var rsb = wh - nav - underNav - 20;
      // var pdfDiv = wh - nav - underNav - 80
      var pdfDiv = wh - nav;
      var wh = wh - nav - underNav;
      $("#order--dynamic--height").css("height", h);
      $(".side-attachments,.side-attachments-2").css("height", rsb);
      $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);

      $(window).resize(function () {
        var vw = $(window).width();
        // if (vw > 1930) {
        var nav = $(".navbar.fixed-top").height();
        var underNav = $(".order-tabs").height();
        var wh = $(window).height();
        var h = wh - nav - 60;
        var rsb = wh - nav - underNav - 20;
        // var pdfDiv = wh - nav - underNav - 80
        var pdfDiv = wh - nav;
        var wh = wh - nav - underNav;
        $("#order--dynamic--height").css("height", h);
        $(".side-attachments,.side-attachments-2").css("height", rsb);
        $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);
        // }
      });
      if ($(window).width() >= 2045) {
        $(".invoice_pdf_new").addClass("invoice_pdf_new1");
      }
      if ($(window).width() < 2045) {
        $(".invoice_pdf_new").addClass("invoice_pdf_new1");
      }
    });

    $(".cus-arro-div2").on("click", function (e) {
      e.preventDefault();
      $(".invoice_carousel").toggleClass("expand_invoice_img");
    });
    $(".export_crd").click(function () {
      $(".export_crd .sideBarAccord1").toggleClass("rotate_0");
    });
    $(".cus-arro-div2").on("click", function (e) {
      e.preventDefault();
      $(".order_pdf_new").toggleClass("order_left_auto");
    });
    // end

    // sideBarAccord
    $(".invoice-inherit").click(function () {
      $(".invoice-inherit .sideBarAccord1 ").toggleClass("rotate_0");
    });
    $(".bank_details").click(function () {
      $(".bank_details .sideBarAccord1 ").toggleClass("rotate_0");
    });
    $(".invoice-batchlist").click(function () {
      $(".invoice-batchlist .sideBarAccord1").toggleClass("rotate_0");
    });
    $(".sideBarAccord").click(function () {
      $(this).toggleClass("rorate_0");
    });
    $(".sideBarAccord").dblclick(function () {
      $(this).toggleClass("rorate_0");
    });
    $(".sideBarAccord1").dblclick(function () {
      $(this).toggleClass("rorate_0");
    });
    $(".sideBarAccord2").dblclick(function () {
      $(this).toggleClass("rorate_0");
    });

    // end

    let {
      dashboard,
      tallType,
      editInvoiceTran,
      tallies,
      editInvoiceCheck,
      guid,
      page,
      edited
    } =
      (this.props.history &&
        this.props.history.location &&
        this.props.history.location.state) ||
      "";
    if (guid) {
      this.setState({ guid: guid , refreshedClicked:true, selectedGuid: guid});
    }
    if (page) {
      this.setState({ activatedPage: page });
    }
    if (edited){
      this.setState({ edited: true });
    }
    if (dashboard && tallType) {
      //when user click on Invoices Tallies on Dashboard
      await this.getInvoiceTallies(tallType, true); // get Invoice Tallies
    } else if (
      tallies &&
      tallies === "Draft" &&
      editInvoiceCheck &&
      editInvoiceTran
    ) {
      /*When  draft/Edit and Invoice or Order  and then user Save or Cancel that edit, 
        then load the same Invoice or Order user just edited?.*/
      await this.getInvoiceTallies("Draft", true);
    } else {
      await this.getInvoiceTallies(); // get Invoice Tallies
    }

    await Promise.all([this.getChartCodes("", "all"), this.getBtachList()]);

    this.props.clearChartStates();
    this.props.clearUserStates();
    this.props.clearInvoiceStates();
    const approvers = await this.getApprovers();

    this.setState({ ...approvers });
  }

  componentWillUnmount() {
    if (this.state && this.state.resetPage) {
      this.props.resetLastPage("invoices");
    }
  }

  refreshTalliesAndList = async () => {
    this.setState({refreshedGuid: this.state.selectedGuid, refreshedClicked:true})
    await this.getInvoiceTallies('', true);
  }
  handleInvoiceTallies =async (type, check) =>{
    this.clonedBatchListGenerate();
    this.setState({ selectedBatchNos:[] });
    this.getInvoiceTallies(type, check);
  }

  //get invoice talleis
  getInvoiceTallies = async (type, check) => {
    let approvers = {
      approvalsGroups: [],
      approvalOptions: [],
    }
    this.setState({ checked: false, batchNo: "", allSelected: false, multipleSelectedGuid:[]});
    //check -> when a user Perform some actions like send for approval, Approve, Declined etc then updated Invoice Tallies
    this.setState({ isLoading: true });
    if(type && !this.state.isSendForApproval ){
      this.setState({ page: 1 })
    }
    if(!type){
      if (this.state.activatedTab){
        type = this.state.activatedTab
      }
    }
    this.setState({  activatedTab: type })
    let isInvoiceTallies = false; //to check if redux store containe invoice tallies then dont call API again
    let _invoiceTallies = this.props.invoiceData.invoiceTallies || [];
    let _type = "";

    if (_invoiceTallies.length === 0 || check) {
      await this.props.getInvoiceTallies(); // get Invoice Tallies
    } else {
      isInvoiceTallies = true;
    }
    let invcTally = "";

    let { activeInvoiceTallis, showInvoiceTallisTabPane, count } = this.state;

    let invcTalliesArr = [];

    //success case of Invoice tallies
    if (this.props.invoiceData.getInvoiceTalliesSuccess || isInvoiceTallies) {
      // toast.success(this.props.invoiceData.getInvoiceTalliesSuccess);
      let invoiceTallies = this.props.invoiceData.invoiceTallies || [];
      let invcTypes = [];

      let userType = localStorage?.getItem("userType");
      userType = userType ? userType?.toLowerCase() : "";

      if (userType == "operator") {
        invcTypes = ["draft", "pending", "declined", "approved", "all"];
      } else if (userType == "approver") {
        invcTypes = [
          "approve",
          "hold",
          "pending",
          "declined",
          "approved",
          "all",
        ];
      } else if (userType == "op/approver") {
        invcTypes = [
          "draft",
          "approve",
          "hold",
          "pending",
          "declined",
          "approved",
          "all",
        ];
      }

      if (invcTypes.length > 0) {
        invcTypes.map((t, i) => {
          let obj = invoiceTallies.find(
            (tl) => tl.type && tl.type.toLowerCase() === t
          );
          if (obj) {
            invcTalliesArr.push(obj);
          }
        });
      } else {
        invcTalliesArr = invoiceTallies;
      }

      if (type) {
        _type = type;
      } else if (invcTalliesArr.length > 0) {
        _type = invcTalliesArr[0].type;
      }

      invcTalliesArr.map(async (invc, i) => {
        if (invc.type === _type) {
          let id = uuidv1();
          invc.id = id;
          invcTally = invc;
          activeInvoiceTallis = id;
          if (type === this.state.showInvoiceTallisTabPane && type !== "") {
            showInvoiceTallisTabPane = this.state.showInvoiceTallisTabPane;
          } else {
            showInvoiceTallisTabPane = invc.type;
          }
          count = invc.count;
        } else {
          invc.id = uuidv1();
        }
        return invc;
      });
      approvers = await this.getApprovers();
    }
    //error case case of Invoice tallies
    if (this.props.invoiceData.getInvoiceTalliesError) {
      handleAPIErr(this.props.invoiceData.getInvoiceTalliesError, this.props);
    }
    this.setState({
      isLoading: false,
      invoiceTallies: invcTalliesArr,
      activeInvoiceTallis,
      showInvoiceTallisTabPane,
      count,
    });
    // if (invcTally) {
    //   //to call get invoices List baseed on first invoice tallies
    //   await this.getInvoicesList(invcTally);
    // }
    if (type !== _type) {
      //to call get invoices List baseed on first invoice tallies
      await this.checkPOList_API(invcTally, check);
    } else {
      await this.checkPOList_API(invcTally, check);
    }
    await this.props.clearInvoiceStates();
    if (approvers.approvalOptions.length > 0) {
      this.setState({
        ...approvers,
      });
    }
  };

  //getting the purchase order list when click on Draft || Pending || Approved etc
  getInvoicesList = async (data) => {
    let activeInvoice = "";
    let getInvoicesList = [];
    let clonedGetInvoicesList = [];
    let filteredInvoicesList = [];

    this.clearStates();

    this.setState({
      isLoading: true,
      activeInvoiceTallis: data.id,
      showInvoiceTallisTabPane: data.type,
      invoicesListSearch: "",
      zero: false,
      close: false,
      fullyApproved: false,
    });
    let teamInvcCheck = this.state.viewTeam;
    if (teamInvcCheck) {
      data.teamInvoices = teamInvcCheck;
    }
    await this.props.getInvoicesList(data); // get invoice list
    let firstInvoice = "";
    //success case of getInvoicesListSuccess
    if (this.props.invoiceData.getInvoicesListSuccess) {
      // toast.success(this.props.invoiceData.getInvoicesListSuccess);
      let _getInvoicesList = this.props.invoiceData.getInvoicesList || [];

      let sortFilterInvc = this.state.sortFilterInvc;
      let sortFilterCheckInvc = this.state.sortFilterCheckInvc;

      _getInvoicesList
        .sort((a, b) => {
          if (sortFilterInvc === "amount" || sortFilterInvc === "tran") {
            let valueA = Number(a[sortFilterInvc]);
            let valueB = Number(b[sortFilterInvc]);
            //for ascending order
            if (sortFilterCheckInvc === "ASC") {
              if (valueA < valueB) {
                return -1;
              }
              if (valueA > valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            } else {
              //for descending order

              if (valueA > valueB) {
                return -1;
              }
              if (valueA < valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            }
          } else if (sortFilterInvc === "date") {
            let valueA = new Date(a.date);
            let valueB = new Date(b.date);
            //for ascending order
            if (sortFilterCheckInvc === "ASC") {
              if (valueA < valueB) {
                return -1;
              }
              if (valueA > valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            } else {
              //for descending order

              if (valueA > valueB) {
                return -1;
              }
              if (valueA < valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            }
          } else if (sortFilterInvc) {
            let valueA = a[sortFilterInvc].toString().toUpperCase();
            let valueB = b[sortFilterInvc].toString().toUpperCase();
            //for ascending order
            if (sortFilterCheckInvc === "ASC") {
              if (valueA < valueB) {
                return -1;
              }
              if (valueA > valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            } else {
              //for descending order

              if (valueA > valueB) {
                return -1;
              }
              if (valueA < valueB) {
                return 1;
              }
              return 0;
              // codes must be equal
            }
          }
        })
        .map((invc, i) => {
          if (i == 0) {
            let id = uuidv1();
            invc.id = id;
            invc.checked = false;
            firstInvoice = invc;
            activeInvoice = id;
          } else {
            invc.id = uuidv1();
            invc.checked = false;
          }
          return invc;
        });
      getInvoicesList = _getInvoicesList;
      clonedGetInvoicesList = _getInvoicesList;
      filteredInvoicesList = _getInvoicesList;

      /*Check When  Edit and Invoice or Order  and then user Save or Cancel that edit, 
    then load the same Invoice or Order user just edited?.*/

      let { editInvoiceTran, tallies, editInvoiceCheck } =
        (this.props.history &&
          this.props.history.location &&
          this.props.history.location.state) ||
        "";

      if (
        tallies &&
        tallies === "Draft" &&
        editInvoiceCheck &&
        editInvoiceTran
      ) {
        let checkInvoice = getInvoicesList.find(
          (l) => l.tran === editInvoiceTran
        );
        if (checkInvoice) {
          firstInvoice = checkInvoice;
          activeInvoice = checkInvoice.id;
        }
      }
    }
    //error case of invoices List
    if (this.props.invoiceData.getInvoicesListError) {
      handleAPIErr(this.props.invoiceData.getInvoicesListError, this.props);
    }
    this.setState({
      activeInvoice,
      getInvoicesList,
      clonedGetInvoicesList,
      filteredInvoicesList,
      isLoading: false,
    });
    if (firstInvoice) {
      //to call get invoice baseed on first invoice in invoice list
      await this.getInvoice(firstInvoice, true);
    }
    this.props.clearInvoiceStates();

    // scroll to active invoice
    // var elmnt = document.getElementById(this.state.activeInvoice);
    // if (elmnt) {
    //   elmnt.scrollIntoView();
    // }
  };

  checkPOList_API = (data, check) => {
    let usePageLoading = localStorage.getItem("usePageLoading") || "N";
    usePageLoading = usePageLoading.toLocaleLowerCase();

    //determines if the GetPOList (N) or GetNewPOList (Y) is used.

    if (usePageLoading === "y") {
      this.getNewInvoiceList(data, check);
    } else {
      this.getInvoicesList(data, check);
    }
  };

  getNewInvoiceList = async (data, check) => {
    let { activeInvoice } = this.state;
    let getInvoicesList = [];
    let clonedGetInvoicesList = [];
    let filteredInvoicesList = [];
    let totalPages = "";
    let pageStart = "";
    let pageEnd = "";
    let totalInvoices = "";
    let {
      zero,
      close,
      page,
      displayRecords,
      sortFilterInvc,
      viewTeam,
      invoicesListSearch,
      count,
      order,
      selectedBatchNos
    } = this.state;

    if (check === "tallies") {
      zero = false;
      close = false;
    }
    if (count === 1) {
      page = this.props.pageData?.pageNumber ?? 1;
      this.setState({ count: 0 });
    }

    // if (check) {
    //   page = this.props.pageData?.pageNumber ?? 1;
    // }

    this.clearStates();
    this.setState({
      isLoading: true,
      activeInvoiceTallis: data.id,
      showInvoiceTallisTabPane: data.type,
      zero,
      close,
      page,
    });


    let obj;
    if(this.state.selectedGuid && this.state.refreshedClicked){
      this.setState({guid: this.state.selectedGuid})
    }else{
      this.setState({guid: null})
    }
    let invoiceType = this.state.activatedTab ?  this.state.activatedTab : getActivatedTab();
    if (this.state.batchNo) {
      obj = {
        invoiceType: invoiceType,
        displayRecords,
        sort: sortFilterInvc,
        order: order,
        search: invoicesListSearch,
        page,
        teamInvoices: viewTeam,
        batchList:selectedBatchNos,
      };
    } else {
      if (this.state.activatedPage) {
        obj = {
          invoiceType: invoiceType,
          displayRecords,
          sort: sortFilterInvc,
          order: order,
          search: invoicesListSearch,
          teamInvoices: viewTeam,
        };
      } else {
        if (this.state.guid){
          obj = {
            invoiceType: invoiceType,
            displayRecords,
            sort: sortFilterInvc,
            order: order,
            search: invoicesListSearch,
            teamInvoices: viewTeam,
          }
        } else {
          obj = {
            invoiceType: invoiceType,
            displayRecords,
            sort: sortFilterInvc,
            order: order,
            search: invoicesListSearch,
            teamInvoices: viewTeam,
            page
          }
        }
      }
    }

    if (this.state.guid) {
      await this.props.getNewInvoiceList({ ...obj, guid: this.state.guid }); // get invoice list
    } else {
      await this.props.getNewInvoiceList(obj); // get invoice list
    }
    let firstPO = "";
    //success case of invoice List
    if (this.props.invoiceData.getNewInvoiceListSuccess) {
      // toast.success(this.props.invoiceData.getNewPOListSuccess);
      let listData = this.props.invoiceData.getNewInvoiceList || "";
      getInvoicesList = listData.invoiceList || [];
      totalPages = listData.totalPages || "";

      pageStart = listData.pageStart || "";
      pageEnd = listData.pageEnd || "";
      totalInvoices = listData.totalInvoices || "";
      page = listData.page || ""
      if (this.state.activatedPage) {
        this.setState({ page: this.props.location.state.page });
      }
      let searchPOTran = "";
      // when a user comes form Search page then show the PO comming from Search page

      let { po, searchPO } =
        (this.props.history &&
          this.props.history.location &&
          this.props.history.location.state) ||
        "";
      if (po && po.type && po.type.toLowerCase() === data.type.toLowerCase()) {
        if (po && po.trans && po.type && searchPO) {
          let order = getInvoicesList.find(
            (l) => Number(l.trans) === Number(po.trans)
          );

          if (order) {
            getInvoicesList.map(function (l, i) {
              if (l.trans === order.trans) {
                getInvoicesList.splice(i, 1);
                getInvoicesList.unshift(l);

                searchPOTran = l.trans;
              }
            });
          } else {
            //push order comming frm search page
            getInvoicesList.unshift(po);
            searchPOTran = po.trans;

            let { poTallies } = this.state;
            //also increase the count of Tallies
            let f = poTallies.find(
              (p) => p.type.toLowerCase() === po.type.toLowerCase()
            );
            f.count = getInvoicesList.length || Number(f.count);
            var foundIndex = poTallies.findIndex(
              (x) => x.type.toLowerCase() == po.type.toLowerCase()
            );
            poTallies[foundIndex] = f;
          }
        }
      }
      let guid;
      if(this.state.refreshedClicked){
        guid = this.state.selectedGuid;
      }else{
        guid = null;
      }

      getInvoicesList.map((po, i) => {
        if (i === 0  || po.guid === guid) {
          let id = uuidv1();
          po.id = id;
          firstPO = po;
          po.checked = false;
          activeInvoice = id;
        } else {
          po.id = uuidv1();
          po.checked = false;
        }
        return po;
      });

      clonedGetInvoicesList = getInvoicesList;
      filteredInvoicesList = getInvoicesList;

      // check if po comming from search page
      let checkPO = getInvoicesList.find((l) => l.tran === searchPOTran);
      if (checkPO) {
        firstPO = checkPO;
        activeInvoice = checkPO.id;
      }

      // debugger;

      let { editInvoiceTran, editInvoiceCheck, tallies, editRefresh } =
        (this.props.history &&
          this.props.history.location &&
          this.props.history.location.state) ||
        "";

      /*Check When Add/Edit Order and then user Save or Cancel that edit,
        then load the same  Order user just edited/created?.*/
      if (
        tallies &&
        tallies === "Draft" &&
        editInvoiceCheck &&
        editInvoiceTran &&
        editRefresh === "invoice" &&
        this.state.guid
      ) {
        let checkInvoice1 = getInvoicesList.find(
          (l) => l.tran === editInvoiceTran
        );
        if (checkInvoice1) {
          firstPO = checkInvoice1;
          activeInvoice = checkInvoice1.id;
        }
      }
      // if (
      //   tallies &&
      //   tallies === "Draft" &&
      //   editInvoiceCheck &&
      //   editInvoiceTran
      // ) {
      //   let checkInvoice = clonedGetInvoicesList.find(
      //     (l) => l.tran === editInvoiceTran
      //   );
      //   if (checkInvoice) {
      //     // firstInvoice = checkInvoice;
      //     activeInvoice = checkInvoice.id;
      //     firstPO.id = activeInvoice.id;
      //   }
      // }
      this.setState({ refreshedClicked:false})
    }
    //error case of PO List
    if (this.props.invoiceData.getNewPOListError) {
      handleAPIErr(this.props.invoiceData.getNewPOListError, this.props);
    }
    await this.props.clearInvoiceStates();
    this.setState(
      {
        activeInvoice,
        getInvoicesList,
        clonedGetInvoicesList,
        filteredInvoicesList,
        totalPages,
        pageStart,
        pageEnd,
        totalInvoices,
        isLoading: false,
        guid: null,
      },
      () => {
        // var elmnt = document.getElementById(this.state.activeInvoice);
        // if (elmnt) {
        //   elmnt.scrollIntoView();
        // }
      }
    );
    if (this.state.edited){
      this.setState({ page: page, edited: false})
    }

    if (firstPO) {
      //to call get po baseed on first po in po list
      await this.getInvoice(firstPO, true);
    }
    await this.props.clearInvoiceStates();
    //Can you ensure that checkboxes on the right hand side stay ticked when in the same section for Exclude?
    if (zero || close) {
      this.handleExclude();
    }
    // scroll to active po
  };

  getPreviewableAttachments = (attachments) => {
    if (!Array.isArray(attachments)) {
      console.warn("Argument should be an array");
      return [];
    }
    const clonedAttachments = [...attachments];
    const primaryAttachmentIndex = clonedAttachments.findIndex((a) => a?.primaryDoc === "Y");
    if (primaryAttachmentIndex !== -1 && clonedAttachments[0]?.primaryDoc === "N") {
      // makes sure that the primary document is the first one. for some reason while testing
      // the order of attachments and previews are not matching
      clonedAttachments.unshift(clonedAttachments.splice(primaryAttachmentIndex, 1)[0]);
    }
    const previewFiltered = clonedAttachments.filter((a) => a?.preview === 1);
    if (previewFiltered.length === 0) {
      // try content type filtering
      return clonedAttachments.filter((a) => a?.contentType === "application/pdf");
    }
    return previewFiltered;
  }

  getApprovers = async () => {
    const approvalOptions = await InvoiceActions.getInvoiceApprovalGroups();
    const approvalsGroups = [ ...approvalOptions ];
    const modifiedGroups = approvalsGroups.map((a, i) => {
      a.checked = false;
      a.id = uuidv1();
      return a;
    });
    const state = {
      approvalsGroups: modifiedGroups,
      approvalOptions,
    };
    return state;
  }

  getInvoiceLines = async(guid) => {
    let lines = await InvoiceActions.getInvoiceLines(guid);

    let subTotal = 0;

    const invoiceLines = lines.map((line, i) => {
      line.id = uuidv1();
      line.checked = false;

      if (line.startTime && line.startTime != "0") {
        line.startTime *= 1000;
      }
      if (line.endTime && line.endTime != "0") {
        line.endTime *= 1000;
      }
      line.amount = Number(line.amount).toFixed(2) || 0.0;

      subTotal = Number(subTotal) + Number(line.amount);

      return line;
    });

    this.setState({
      isLoading: false,
      lines: invoiceLines,
      subTotal: Number(subTotal).toFixed(2),
    });
  }

  /**
   * @param {Event} event
   */
  isCollapseExpanded = (event) => {
    if (!event) {
      throw new Error('Event is required');
    }
    const isExpanded = document.querySelector('#Activity_invoice').classList.contains('show');
    const isAiraCollapseSupported = event.currentTarget.ariaExpanded !== undefined || event.currentTarget.ariaExpanded !== null;
    const isAiraExpanded = isAiraCollapseSupported ? event.currentTarget.ariaExpanded === 'true' : isExpanded;
    return isAiraExpanded;
  }

  /**
   * Gets the invoice activities, this is called in 2 different places
   * one is when the collapse button is clicked another is when Button is clicked
   * depending on which one is called fetches the activities. It fetches one time per invoice
   * and stores the activities in the state. so that we do not fetch them again.
   * @param {string} invoiceGuid
   * @param {Event?} event
   */
  getInvoiceActivity = async (invoiceGuid, event) => {
    const {
      activiesFetchedFor,
    } = this.state;
    const isAlreadyFetched = activiesFetchedFor === invoiceGuid;
    const isFromCollapse = event !== undefined;
    const isCurrentlyExpanded = isFromCollapse && this.isCollapseExpanded(event);
    if (!isFromCollapse && isAlreadyFetched) {
      if (!isFromCollapse) {
        this.openModal("openActivityModal")
      }
      return;
    }
    if ((isFromCollapse && isCurrentlyExpanded) || isAlreadyFetched) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const activitiesResponse = await InvoiceActions.getInvoiceActivity(invoiceGuid);
    const isSuccessful = activitiesResponse.results[0].status?.toLowerCase()?.trim() === 'success';
    let activities = [];
    if (!isSuccessful) {
      toast.error(activitiesResponse.results[0]?.description);
    } else {
      activities = activitiesResponse.activity;
      toast.success(activitiesResponse.results[0]?.description);
    }
    this.setState({
      isLoading: false,
      invoiceActivity: activities,
      activiesFetchedFor: invoiceGuid,
    }, () => {
      if (!isFromCollapse) {
        this.openModal("openActivityModal")
      }
    });
  }

  //getting the single invoice
  getInvoice = async (invc, check) => {
    if (this.state.activeInvoice != invc.id || check) {
      this.setState({
        isLoading: true,
        previewList: [],
        numPages: null,
        pageNumber: 1,
        rotate: 0,
        numPagesArr: [], //it contains number of pages of each PDF
        attachmentSize: 0,
        activeInvoice: invc.id,
        currency: "",
        supplierCode: "",
        bankDetails: {},
        selectedGuid: invc.guid,
        supplierGuid: invc.supplierGuid,
        currentAttachmentIndex: 0,
        invoiceActivity: [],
      });

      await this.props.getInvoiceSummary(invc.guid);
      if (this.props.invoiceData.getInvoiceSuccess) {
        const invoiceFromProps = this.props?.invoiceData?.getInvoice?.invoice || {};
        let invoiceDetails = { ...invoiceFromProps };
        let tran = invoiceDetails?.tran || "";
        let comments = invoiceDetails?.comments || [];
        let attachments = invoiceDetails?.attachments || [];
        const previewableAttachments = this.getPreviewableAttachments(attachments);
        let department = invoiceDetails?.department || "";
        let supplierName = invoiceDetails?.supplierName || "";
        let description = invoiceDetails?.description || "";
        let invoiceNumber = invoiceDetails?.invoiceNumber || "";
        let requestedBy = invoiceDetails?.requestedBy || "";
        let payDate = invoiceDetails?.payDate || "";
        let dueDate = invoiceDetails?.dueDate || "";
        let supplierCode = invoiceDetails?.supplierCode || "";
        let approved = invoiceDetails?.approved || ""; // ! Get summary or Get invoice does not give this object
        let invoiceDate = invoiceDetails?.invoiceDate || "";
        let supplierEmail = invoiceDetails?.supplierEmail || "";
        let advancedList = invoiceDetails?.advancedList || []; // ! Get summary or Get invoice does not give this object
        let supplierGuid =  invoiceDetails.supplierGuid || "";
        advancedList.map((lst, i) => {
          if (lst.valueType && lst.valueType.toLowerCase() === "list") {
            let valOptns = [];
            if (lst.valueOptions && lst.valueOptions.length > 0) {
              lst.valueOptions.map((o, i) => {
                valOptns.push({ label: o.option, value: o.option });
              });
            }
            lst.valueOptions = valOptns;
          }
          lst.id = uuidv1();
          lst.hide = false;
          return lst;
        }); // Does not even make sense. They are using map to loop around. push the value into an array which is inside the map :shrug:
        // I guess advancedList is pretty useless now ??

        let attachmentSize = 0;
        attachments.map((a, i) => {
          attachmentSize += Number(a.fileSize) || 0;
        });
        // This is another useless thing. as the file size does not come with attachments. looping is useless

        let approvers = invoiceDetails?.approvers || [];

        let approvalGroup = invoiceDetails?.approvalGroup || "";


        let invoiceChanges =
          (this.props.invoiceData &&
            this.props.invoiceData.getInvoice &&
            this.props.invoiceData.getInvoice.invoiceChanges) ||
          [];

        let previewList = invoiceDetails?.previewList || [];

        if (isRemoteStorage()) {
          previewList = await Promise.all(previewList.map(async (p) => {
            p.file = await getBase64FromPreSignedS3Url(p.documentPresignedURL);
            return p;
          }));
        }

        // Investigate this
        let poLines = invoiceDetails?.poLines || [];

        let basisOptions = await UserActions.getBasis();

        let currency = invoiceDetails?.currency || "";

        this.setState(
          {
            tran,
            comments,
            attachments,
            previewableAttachments,
            attachmentSize,
            approvers,
            invoiceChanges,
            poLines,
            approvalGroup: {
              label: approvalGroup ? approvalGroup : "Select Approval Group",
              value: approvalGroup ? approvalGroup : "",
            },
            basisOptions,
            previewList,
            currency,
            supplierCode,
            supplierName,
            description,
            department,
            payDate,
            dueDate,
            approved,
            invoiceNumber,
            invoiceDate,
            supplierEmail,
            advancedList,
            advancedListEdit: advancedList,
            supplierGuid:supplierGuid,
            requestedBy
          },
        );
      }
      //error case of get invoice
      if (this.props.invoiceData.getInvoiceError) {
        handleAPIErr(this.props.invoiceData.getInvoiceError, this.props);
      }
      this.props.clearInvoiceStates();
      this.setState({ isLoading: false });
      //setting the invoice zoom
      let invoiceZoom = localStorage.getItem("invoiceZoom");
      if (invoiceZoom) {
        this.handleDropdownZooming({ value: invoiceZoom });
      }
    }
  };
  updateInvoiceSummaryState = async () => {
    if (this.props.invoiceData.updateApprovalGroupSuccess) {
      toast.success(this.props.invoiceData.updateApprovalGroupSuccess);
    }
    if (this.props.invoiceData.updateApprovalGroupError) {
      handleAPIErr(this.props.invoiceData.updateApprovalGroupError, this.props);
    }
  };

  getChartCodes = async (sort, check) => {
    //if check == all it means that store all type chartCodes for the first time(when call api in didmount )
    //it is because when line item modal open and we call getChartCodes according to selected Chart sort then state contains only that chart codes related to select chart sorts
    //these all chart codes will be used for chart code auto-completion to show related to the chart sort in the line (filter codes according to sort in the line)
    await this.props.getChartCodes(sort); //to get chart codes filterd list according to chart sort

    //success case of Get Chart Codes
    if (this.props.chart.getChartCodesSuccess) {
      // toast.success(this.props.chart.getChartCodesSuccess);

      let getChartCodes = this.props.chart.getChartCodes || "";

      if (check === "all") {
        //this will contains all chart codes
        this.setState({
          chartCodesList: getChartCodes.chartCodes || [],
          clonedChartCodesList: getChartCodes.chartCodes || [],
        });
      }
    }

    //error case of Get Chart Codes
    if (this.props.chart.getChartCodesError) {
      handleAPIErr(this.props.chart.getChartCodesError, this.props);
    }
    this.props.clearChartStates();
  };

  //handle auto-completing and typing into the Chart Code
  //handle auto-completing and typing into the Chart Code
  handleChangeChartCode = async (e, line, i) => {
    $(`.chart${i}`).show();
    let { name, value } = e.target;
    const copyArr = [...this.state.lines];
    let clonedChartCodesList = [...this.state.chartCodesList];
    // update in po lines

    line.chartCode = value || "";
    copyArr[i] = line;

    let chartCodesListFilterdData = await clonedChartCodesList.filter((c) => {
      return (
        (c.code.toUpperCase().includes(value.toUpperCase()) ||
          c.description.includes(value.toUpperCase())) &&
        c.sort.toUpperCase() === String(line.chartSort).toUpperCase()
      );
    });
    clonedChartCodesList = chartCodesListFilterdData;
    this.setState({ lines: copyArr, clonedChartCodesList });
  };
  handleInLine = async (e, d, i, sequence) => {
    this.onblurCode(i);
    let { name, value } = e.target;
    let { currencySort, locationSort, episodeSort, getFlags } = this.state;
    const copyArr = [...this.state.lines];
    const clonedChartCodesList = [...this.state.clonedChartCodesList];
    let dotsCounter = "";
    // chartCode part  ------------------------------------------------
    if (name === "chartCode") {
      if (!value) {
        clonedChartCodesList = [];
        copyArr[i].chartDescription = "Required";
        copyArr[i] = {
          ...copyArr[i],
          errorMessageCode: "Required",
        };
      } else {
        copyArr[i] = { ...copyArr[i], errorMessageCode: "" };
        const result = await clonedChartCodesList.findIndex(
          (s) => s.code === value
        );
        if (result === -1) {
          let foundIndex = copyArr.findIndex((l) => l.id == d.id);
          if (foundIndex != -1) {
            // line.chartDescription = "invalid";
            copyArr[i].chartDescription = "***Invalid Account Code***";

            copyArr[i] = {
              ...copyArr[i],
              errorMessageCode: "Invalid",
            };
          }
        } else {
          copyArr[i].chartDescription =
            clonedChartCodesList[result].description;
          copyArr[i] = {
            ...copyArr[i],
            errorMessageCode: "",
          };
        }
      }
    }

    // let a = currencySort.findIndex(
    //   (f) => f.code === value.toUpperCase().slice(0, 2)
    // );
    // let b = locationSort.findIndex(
    //   (f) => f.code === value.toUpperCase().slice(3, 5)
    // );
    // let c = episodeSort.findIndex(
    //   (f) => f.code === value.toUpperCase().slice(6, 9)
    // );
    // let srchIndx = value.search("\\.");
    // if (srchIndx !== -1) {
    //   dotsCounter = value.match(new RegExp("\\.", "g")).length;
    // }

    // if (name === "chartSort") {
    //   if (value.length < 1) {
    //     copyArr[i] = {
    //       ...copyArr[i],
    //       sortErrorMsg: "Required",
    //     };
    //   } else if (dotsCounter !== 2) {
    //     if ("." !== value.slice(2, 3)) {
    //       copyArr[i] = {
    //         ...copyArr[i],
    //         sortErrorMsg: "Dot Missing!",
    //       };
    //     } else if ("." !== value.slice(5, 6)) {
    //       copyArr[i] = {
    //         ...copyArr[i],
    //         sortErrorMsg: "Dot Missing!",
    //       };
    //     }
    //   } else if (a === -1) {
    //     copyArr[i] = {
    //       ...copyArr[i],
    //       sortErrorMsg: "CU Invalid",
    //     };
    //   } else if (b === -1) {
    //     copyArr[i] = {
    //       ...copyArr[i],
    //       sortErrorMsg: "LOC Invalid",
    //     };
    //   } else if (c === -1) {
    //     copyArr[i] = {
    //       ...copyArr[i],
    //       sortErrorMsg: "EPS Invalid",
    //     };
    //   } else {
    //     copyArr[i] = { ...copyArr[i], sortErrorMsg: "" };
    //   }
    // }
    // // chartCode part  ------------------------------------------------
    // if (name === "chartCode") {
    //   if(!value){
    //     clonedChartCodesList = [];
    //     copyArr[i].chartDescription = "Required";
    //     copyArr[i] = {
    //       ...copyArr[i],
    //       errorMessageCode: "Required",
    //     };
    //   }else{
    //     copyArr[i] = { ...copyArr[i], errorMessageCode: "" };
    //     const result = await clonedChartCodesList.findIndex(
    //       (s) => s.code === value
    //     );
    //     if (result === -1) {
    //       let foundIndex = copyArr.findIndex((l) => l.id == d.id);
    //       if (foundIndex != -1) {
    //         // line.chartDescription = "invalid";
    //       copyArr[i].chartDescription = "Invalid";

    //         copyArr[i] = {
    //           ...copyArr[i],
    //           errorMessageCode: "Invalid",
    //         }
    //       }
    //     } else {
    //       copyArr[i].chartDescription = clonedChartCodesList[result].description;
    //       copyArr[i] = {
    //         ...copyArr[i],
    //         errorMessageCode: "",
    //       };
    //     }
    //   }
    // }
    // // insurance part -------------------------------------------------
    // let flags = d.flags || [];
    // let insurance = getFlags.insurance || [];
    // flags.map((f, i) => {
    //   if (
    //     f.type &&
    //     f.type.toLowerCase() == name.toLowerCase() &&
    //     name === "Insurance"
    //   ) {
    //     f.errorMessageIn = "";
    //     const result = insurance.findIndex((m) => m.code === value);
    //     if (result === -1) {
    //       f.errorMessageIn = "Invalid";
    //     } else {
    //       f.errorMessageIn = "";
    //     }
    //   }
    // });
    // d.flags = flags;

    // // flag part ------------------------------------------------
    // flags = d.flags || [];
    // let free = getFlags.free || [];
    // flags.map((f, i) => {
    //   if (
    //     f.type &&
    //     f.type.toLowerCase() == name.toLowerCase() &&
    //     name === "Free"
    //   ) {
    //     f.errorMessageF = "";
    //     const result = free.findIndex((m) => m.code === value);
    //     if (result === -1) {
    //       f.errorMessageF = "Invalid";
    //     } else {
    //       f.errorMessageF = "";
    //     }
    //   }
    // });

    // // update in po lines
    // d.flags = flags;
    // // Tax part ----------------------------------------------------
    // flags = d.flags || [];
    // let tax = getFlags.tax || [];
    // flags.map((f, i) => {
    //   if (
    //     f.type &&
    //     f.type.toLowerCase() == name.toLowerCase() &&
    //     name === "Tax"
    //   ) {
    //     f.errorMessageTax = "";
    //     const result = tax.findIndex((m) => m.code === value);
    //     if (result === -1) {
    //       f.errorMessageTax = "Invalid";
    //     } else {
    //       f.errorMessageTax = "";
    //     }
    //   }
    // });

    //    // Set part ------------------------------------------------
    //    flags = d.flags || [];

    //    flags.map((f, i) => {
    //      if (
    //        f.type &&
    //        f.type.toLowerCase() == name.toLowerCase() &&
    //        name === "Set"
    //      ) {
    //       let set = getFlags.set || [];
    //       const result = set.findIndex((m) => m.code === value);
    //       if (result === -1) {
    //         f.errorMessageSet = "Invalid";
    //       } else {
    //         f.errorMessageSet = "";
    //       }
    //      }
    //    });

    //           // Q to Rb3 part ------------------------------------------------
    //           flags = d.flags || [];
    //           flags.map((f, i) => {
    //             if (
    //               f.type &&
    //               f.type.toLowerCase() == name.toLowerCase() && f.sequence === sequence &&
    //               name === "Rebate"
    //             ) {
    //               let rebate = getFlags.rebate || [];
    //               const result = rebate.findIndex((m) => m.code === value);
    //               if (result === -1) {
    //                 f.errorMessageRebate = "Invalid";
    //               } else {
    //                 f.errorMessageRebate = "";
    //               }
    //             }
    //           });

    //              // test part ------------------------------------------------
    //              flags = d.flags || [];
    //              flags.map((f, i) => {
    //                if (
    //                  f.type &&
    //                  f.type.toLowerCase() == name.toLowerCase() && f.sequence === sequence &&
    //                  name === "Other"
    //                ) {
    //                 let other = getFlags.other || [];
    //                 const result = other.findIndex((m) => m.code === value);
    //                 if (result === -1) {
    //                   f.errorMessageTest = "Invalid";
    //                 } else {
    //                   f.errorMessageTest = "";
    //                 }
    //                }
    //              });

    // // update in po lines
    // d.flags = flags;

    this.setState({
      lines: copyArr,
    });
  };

  handleChangeField = (e, line, i) => {
    let name = e.target.name;
    let value = e.target.value;
    // let { lines } = this.state;
    let lines = JSON.parse(JSON.stringify(this.state.lines));

    // update in invoice lines
    let foundIndex = lines.findIndex((l) => l.id == line.id);
    if (foundIndex != -1) {
      line[name] = value || "";
      lines[foundIndex] = line;
    }

    this.setState({ lines });
  };

  convertTwoDecimal = (e, line) => {
    let val = Number(e.target.value).toFixed(2) || 0.0;

    let { lines } = this.state;
    line["amount"] = val;

    // calculation(subTotal)
    let subTotal = 0.0;
    let lns = JSON.parse(JSON.stringify(lines));
    lns.map((l) => {
      subTotal = Number(subTotal) + Number(l.amount);
    });

    this.setState({
      subTotal: Number(subTotal).toFixed(2),
      lines,
    });
  };

  handleChangeFlags = (e, line, i, sequence) => {
    let name = e.target.name;
    let value = e.target.value;
    let lines = JSON.parse(JSON.stringify(this.state.lines));

    let flags = line.flags || [];

    flags.map((f, i) => {
      if (
        f.type &&
        f.type.toLowerCase() == name.toLowerCase() &&
        f.sequence === sequence
      ) {
        f.value = value.toUpperCase();
      }
      return f;
    });

    lines[i] = line;
    this.setState({ lines });
  };

  onblurCode = (i) => {
    setTimeout(() => {
      $(`.chart${i}`).hide();
    }, 700);
  };

  //when select code from suggestions e.g. auto-completion
  changeChartCode = (chartCode, line, index) => {
    //focus after chart code selection to move next on Tab press
    $(`#chrtCode${index}`).focus();
    let copyArr = [...this.state.lines];

    // update in invoice lines
    line.chartCode = chartCode.code || "";
    line.chartDescription = chartCode.description || "";
    copyArr[index] = line;
    copyArr[index] = {
      ...copyArr[index],
      errorMessageCode: "",
    };
    this.setState({ lines: copyArr });
    this.onblurCode(index);
  };

  getChartSorts = async () => {
    if (!this.props.chart.getChartSorts) {
      this.setState({ isLoading: true });

      await this.props.getChartSorts();

      //success case of Get Chart Sorts
      if (this.props.chart.getChartSortsSuccess) {
        // toast.success(this.props.chart.getChartSortsSuccess);
      }
      //error case of Get Chart Sorts
      if (this.props.chart.getChartSortsError) {
        handleAPIErr(this.props.chart.getChartSortsError, this.props);
      }
      this.props.clearChartStates();
      this.setState({ isLoading: false });
    }
  };
  handleChangeNewInvoiceListSearch = (e) => {
    let searchedText = e.target.value;
    this.setState({ invoicesListSearch: searchedText }, () => {
      this.savePageState();
      if (!searchedText) {
        let { activeInvoiceTallis, showInvoiceTallisTabPane } = this.state;
        let obj = {
          id: activeInvoiceTallis,
          type: showInvoiceTallisTabPane,
        };
        this.getNewInvoiceList(obj);
      }
    });
  };

  //when a user searches on PO list
  handleChangeInvoiceListSearch = async (e) => {
    let searchedText = e.target.value;
    this.setState({ invoicesListSearch: searchedText }, () => {
      const filteredInvoicesList = JSON.parse(
        JSON.stringify(this.state.filteredInvoicesList)
      );
      if (!searchedText) {
        let sortFilterCheckInvc = this.state.sortFilterCheckInvc;
        if (sortFilterCheckInvc === "ASC") {
          sortFilterCheckInvc = "DESC";
        } else {
          sortFilterCheckInvc = "ASC";
        }
        this.setState(
          { getPOList: filteredInvoicesList, sortFilterCheckInvc },
          () => {
            this.handleSortInvoiceLists(this.state.sortFilterInvc);
            // scroll to active invoice
            var elmnt = document.getElementById(this.state.activeInvoice);
            if (elmnt) {
              elmnt.scrollIntoView();
            }
          }
        );
      }
    });
  };

  onNewInvoiceListSearch = (e) => {
    if (e.key === "Enter" || e.key === "Tab") {
      let searchedText = e.target.value;
      let usePageLoading = localStorage.getItem("usePageLoading") || "N";
      usePageLoading = usePageLoading.toLocaleLowerCase();
      if (usePageLoading === "y") {
        this.setState(
          {
            invoicesListSearch: searchedText,
            page: 1,
          },
          () => {
            this.savePageState();
            let { activeInvoiceTallis, showInvoiceTallisTabPane } = this.state;

            let obj = {
              id: activeInvoiceTallis,
              type: showInvoiceTallisTabPane,
            };
            this.getNewInvoiceList(obj);
          }
        );
      }
    }
  };

  onInvoiceListSearch = async (e) => {
    if (e.key === "Enter" || e.key === "Tab") {
      let invoicesListSearch = this.state.invoicesListSearch.trim();

      if (invoicesListSearch) {
        const filteredInvoicesList = JSON.parse(
          JSON.stringify(this.state.filteredInvoicesList)
        );
        let invoiceListFilterdData = [];
        invoiceListFilterdData = filteredInvoicesList.filter((c) => {
          return c.supplier
            .toUpperCase()
            .includes(invoicesListSearch.toUpperCase());
        });
        this.setState({ getInvoicesList: invoiceListFilterdData });
      }
    }
  };

  //sorting on invocies list
  handleSortInvoiceLists = (name, check) => {
    let usePageLoading = localStorage.getItem("usePageLoading") || "N";
    let order = "Descend";
    usePageLoading = usePageLoading.toLocaleLowerCase();
    if (usePageLoading === "y") {
      if (this.state.sortFilterInvc === name) {
        if (this.state.order === "Descend") {
          order = "Ascend ";
        }
        if (this.state.order === "Ascend ") {
          order = "Descend";
        }
      }
      this.setState(
        {
          sortFilterInvc: name,
          order,
        },
        () => {
          let {
            activeInvoiceTallis,
            showInvoiceTallisTabPane,
            sortFilterCheckInvc,
          } = this.state;

          let obj = {
            id: activeInvoiceTallis,
            type: showInvoiceTallisTabPane,
          };
          localStorage.setItem("sortFilterInvc", name);
          localStorage.setItem("sortFilterCheckInvc", sortFilterCheckInvc);
          this.getNewInvoiceList(obj);
        }
      );
    } else {
      let { sortFilterCheckInvc } = this.state;
      if (this.state.sortFilterInvc != name) {
        sortFilterCheckInvc = "DESC";
      }

      if (sortFilterCheckInvc === "DESC") {
        sortFilterCheckInvc = "ASC";
      } else {
        sortFilterCheckInvc = "DESC";
      }

      localStorage.setItem("sortFilterInvc", name);
      localStorage.setItem("sortFilterCheckInvc", sortFilterCheckInvc);

      const filteredInvoicesList = JSON.parse(
        JSON.stringify(this.state.filteredInvoicesList)
      );
      let invoiceListFilterdData = [];
      if (name === "amount" || name === "tran") {
        invoiceListFilterdData = filteredInvoicesList.sort(function (a, b) {
          let valueA = Number(a[name]);
          let valueB = Number(b[name]);
          //for ascending order
          if (sortFilterCheckInvc === "ASC") {
            if (valueA < valueB) {
              return -1;
            }
            if (valueA > valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          } else {
            //for descending order

            if (valueA > valueB) {
              return -1;
            }
            if (valueA < valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          }
        });
      } else if (name === "date" || name === "approvalDate") {
        invoiceListFilterdData = filteredInvoicesList.sort(function (a, b) {
          let valueA = "";
          let valueB = "";

          if (name === "date") {
            valueA = new Date(a.date);
            valueB = new Date(b.date);
          } else {
            // valueA = new Date(a.approvalDate);
            // valueB = new Date(b.approvalDate);
            valueA = new Date(
              moment(a.approvalDate.replace(/\s/g, ""), "DD/MM/YYYY")
            );
            valueB = new Date(
              moment(b.approvalDate.replace(/\s/g, ""), "DD/MM/YYYY")
            );
          }

          //for ascending order
          if (sortFilterCheckInvc === "ASC") {
            if (valueA < valueB) {
              return -1;
            }
            if (valueA > valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          } else {
            //for descending order

            if (valueA > valueB) {
              return -1;
            }
            if (valueA < valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          }
        });
      } else if (name) {
        invoiceListFilterdData = filteredInvoicesList.sort(function (a, b) {
          let valueA = a[name].toString().toUpperCase();
          let valueB = b[name].toString().toUpperCase();
          //for ascending order
          if (sortFilterCheckInvc === "ASC") {
            if (valueA < valueB) {
              return -1;
            }
            if (valueA > valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          } else {
            //for descending order

            if (valueA > valueB) {
              return -1;
            }
            if (valueA < valueB) {
              return 1;
            }
            return 0;
            // codes must be equal
          }
        });
      }
      this.setState({
        getInvoicesList: invoiceListFilterdData,
        sortFilterInvc: name,
        sortFilterCheckInvc,
      });
    }
  };

  //when click on more details link
  handleMoreDetails = (data) => {
    // this.setState({ invoiceMoreDetails: data }, () =>
    this.openModal("openInvoiceMoreDetailsModal");
    // );
  };

  //add commnet
  addComment = async (comment, params) => {
    if (this.state.selectedGuid) {
      if (comment) {
        this.setState({ isLoading: true });
        let data = {
          comment,
          ...params,
          guid: this.state.selectedGuid,
        };
        await this.props.addComment(data);
        if (this.props.invoiceData.addCommentSuccess) {
          // toast.success(this.props.invoiceData.addCommentSuccess);
          let comments = this.props.invoiceData.addCommentData || [];
          this.setState({ comments });
        }
        if (this.props.invoiceData.addCommentError) {
          handleAPIErr(this.props.invoiceData.addCommentError, this.props);
        }
        this.props.clearInvoiceStates();
        this.setState({ isLoading: false });
      } else {
        toast.error("Please Enter Comment!");
      }
    } else {
      toast.error("Please Select Invoice First!");
    }
  };

  //add attachment
  addAttachment = async (fileList) => {
    const {selectedGuid} = this.state;
    if (selectedGuid) {
      this.setState({ isLoading: true });
      let data = {
        guid: selectedGuid,
        fileName: fileList[0].fileName,
        attachment: fileList[0].attachment,
        primaryDoc: "Y"
      }

      if (isRemoteStorage()) {
        await uploadFileListToS3(fileList, async (uploadedFiles) => {
          if (uploadedFiles) {
            let attachmentData = {
              guid: selectedGuid,
              fileName: uploadedFiles[0]?.fileName,
              documentGUID: uploadedFiles[0]?.documentGuid,
              primaryDoc: "Y",
            }
            await this.props.addInvoiceAttachments(attachmentData);

            if (this.props.invoiceData.addInvoiceAttachmentSuccess) {
              toast.success(this.props.invoiceData.addInvoiceAttachmentSuccess);
              let attachments = this.props.invoiceData.addInvoiceAttachment || [];
              const previewableAttachments = this.getPreviewableAttachments(attachments);
              let attachmentSize = 0;
              attachments.map((a, i) => {
                attachmentSize += Number(a.fileSize) || 0;
              });
              this.setState({ attachments, attachmentSize, previewableAttachments });
            }
            if (this.props.invoiceData.addInvoiceAttachmentError) {
              handleAPIErr(
                this.props.invoiceData.addInvoiceAttachmentError,
                this.props
              );
            }
            this.props.clearInvoiceStates();
            this.setState({ isLoading: false });
          }
      })}
      else {
        await this.props.addInvoiceAttachments(data);
      }

      if (this.props.invoiceData.addInvoiceAttachmentSuccess) {
        toast.success(this.props.invoiceData.addInvoiceAttachmentSuccess);
        let attachments = this.props.invoiceData.addInvoiceAttachment || [];
        const previewableAttachments = this.getPreviewableAttachments(attachments);
        let attachmentSize = 0;
        attachments.map((a, i) => {
          attachmentSize += Number(a.fileSize) || 0;
        });
        this.setState({ attachments, attachmentSize, previewableAttachments });
      }
      if (this.props.invoiceData.addInvoiceAttachmentError) {
        handleAPIErr(
          this.props.invoiceData.addInvoiceAttachmentError,
          this.props
        );
      }
      this.props.clearInvoiceStates();
      this.setState({ isLoading: false });
    } else {
      toast.error("Please Select an Invoice");
    }
  };

  getAttachment = async (recordID, type, fileName) => {
    this.setState({ isLoading: true });

    await this.props.getInvoiceAttachments(this.state.selectedGuid, recordID);
    if (this.props.invoiceData.getInvocieAttachmentSuccess) {
      // toast.success(this.props.invoiceData.getInvocieAttachmentSuccess);
      let resp = this.props.invoiceData.getInvocieAttachment;
      if(resp.documentPresignedURL){
        downloadFileFromPresignedUrl(resp.documentPresignedURL);
      }else {
        downloadAttachments(resp, fileName);
      }
    }
    if (this.props.invoiceData.getInvocieAttachmentError) {
      handleAPIErr(
        this.props.invoiceData.getInvocieAttachmentError,
        this.props
      );
    }
    this.props.clearInvoiceStates();
    this.setState({ isLoading: false });
  };

  //download preview
  downloadPreview = () => {
    if ( this.state.previewList?.length > 0) {
      const primaryDocument = this.state.previewList?.filter(document => document.primaryDocument === 1);
      if (primaryDocument.length > 0){
        if(primaryDocument[0].documentPresignedURL){
          downloadFileFromPresignedUrl(primaryDocument[0].documentPresignedURL);
        }else {
          const linkSource = `data:application/pdf;base64,${primaryDocument[0].file}`;
          const downloadLink = document.createElement("a");
          const fileName = "attachment.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }else{
        toast.error('No Primary Document available for download.')
      }
    }else {
      toast.error('No Primary Document available for download.')
    }
  };

  downloadSelectedAttachments = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isLoading: true });

    let params = {};

    if (this.state.selectedBatchNos) {
      params.batchList = this.state.selectedBatchNos;
    }

    if (this.state.allSelected) {
      params = {
        ...params,
        selectAll: "Y",
        invoiceType: this.state.showInvoiceTallisTabPane,
        search: this.state.invoicesListSearch,
        teamInvoices: this.state.viewTeam,
        downloadFilters: [
          {
            "includeAll": 1
          }
        ],
      };
    } else {
      params = {
        ...params,
        guidList: this.state.multipleSelectedGuid.length > 0
        ? this.state.multipleSelectedGuid
        : [this.state.selectedGuid],
        downloadFilters: [
          {
            includeAll: this.state.downloadAttachmentsOptions.includeAll ? 1 : 0,
          }
        ],
      };
    }

    await this.props.downloadAttachments(params);

    if (this.props.invoiceData?.downloadInvoiceAttachmentsSuccess) {

      let resp = this.props.invoiceData.downloadInvoiceAttachments;

      if(resp.documentPresignedURL){
        downloadFileFromPresignedUrl(resp.documentPresignedURL);
      }else if(resp.zipAttachment){
          const linkSource = `data:text/plain;base64,${resp.zipAttachment}`;
          const downloadLink = document.createElement("a");
          const fileName = "attachments.zip";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
      }else{
        toast.error("Failed to download invoice attachments");
      }
    } else if (this.props.invoiceData.downloadInvoiceAttachmentsError) {
      toast.error(this.props.invoiceData.downloadInvoiceAttachmentsError);
    } else {
      toast.error("Failed to download invoice attachments");
    }
    this.setState({ isLoading: false });

  }

  clearStates = () => {
    this.setState({
      tran: "", //tran of current selected invoice
      multipleTrans: [], //when user selects multiple Invoices to perform different functionality
      // invoicesListSearch: "", //search on invoices list
      getInvoicesList: [], //side menu (left side) invoices list data
      clonedGetInvoicesList: [], //a copy of  getInvoicesList
      // activeInvoice: "", //to add class active in lists of getting invoices (in left side )
      activeInvoiceTallis: "", //to add class active on invoices tallis
      showInvoiceTallisTabPane: "", //to add class active on invoices tallis below tab pane
      filteredInvoicesList: [], //this contains filterd list and used for searching on it
      teamInvoice: "",
      preview: "", //invoice PDF
      previewList: [],
      numPages: null,
      pageNumber: 1,
      numPagesArr: [], //it contains number of pages of each PDF
      comments: [], //invoice comments
      attachments: [], //invoice attachments
      previewableAttachments: [],
      currentAttachmentIndex: 0,
      approvers: [], //to just show on side menuw bar
      invoiceChanges: [],
      invoiceActivity: [],

      lines: [], //invoice lines
      subTotal: 0, //to just show on invoice-details page sub total
      poLines: [], //po lines

      // exclude filter 'Zero', 'Close' and 'fully Approved'
      zero: false,
      close: false,
      fullyApproved: false,
      approvalsGroups: [], //list of approvals Groups checkboxes to filter Invoice list

      invoiceMoreDetails: "",
      openInvoiceMoreDetailsModal: false,
      openDeleteModal: false,

      currency: "",
      supplierCode: "",
    });
  };

  openModal = async (name, type) => {
    if (name === "openInvoiceDetailModal") {
      this.setState({ isLoading: true });

      if (!this.props.user.getDefaultValues) {
        let defVals = localStorage.getItem("getDefaultValues") || "";
        defVals = defVals ? JSON.parse(defVals) : "";
        if (defVals && defVals.defaultValues) {
          //if localstorage contains the default values then update the Redux State no need to call API
          store.dispatch({
            type: "GET_DEFAULT_VALUES_SUCCESS",
            payload: defVals,
          });
        } else {
          await this.props.getDefaultValues();
        }
      }

      if (!this.props.chart.getFlags) {
        await this.props.getFlags();
      }

      //success case of get default vaues
      if (this.props.user.getDefaultValuesSuccess) {
        // toast.success(this.props.user.getDefaultValuesSuccess);
      }
      //error case of get default vaues
      if (this.props.user.getDefaultValuesError) {
        handleAPIErr(this.props.user.getDefaultValuesError, this.props);
      }

      //success case of Get Flags List
      if (this.props.chart.getFlagsSuccess) {
        // toast.success(this.props.chart.getFlagsSuccess);
      }
      //error case of Get Flags List
      if (this.props.chart.getFlagsError) {
        handleAPIErr(this.props.chart.getFlagsError, this.props);
      }

      // this is for Line Items Modal (Tracking Codes)
      let { flags, clonedFlags } = this.state;
      if (
        this.props.user.getDefaultValues.flags &&
        this.props.user.getDefaultValues.flags.length > 0
      ) {
        flags = [];
        clonedFlags = [];
        this.props.user.getDefaultValues.flags.map((defVal, i) => {
          flags.push(
            {
              type: defVal.prompt,
              label: defVal.prompt,
              value: "",
              id: i,
              sequence: defVal.sequence,
            },
            {
              type: defVal.prompt,
              label: "",
              value: "",
              id: i,
              sequence: defVal.sequence,
            }
          );
          clonedFlags.push({
            type: defVal.prompt,
            value: "",
            prompt: defVal.prompt,
            sequence: defVal.sequence,
          });
        });
      }

      this.setState({
        openInvoiceDetailModal: true,
        isLoading: false,
        flags,
        clonedFlags,
      });
    } else {
      this.setState({ typeMove: type, [name]: true });
    }
  };

  closeModal = (name) => {
    this.setState({ [name]: false });
    let invoiceDetails =
      (this.props.invoiceData.getInvoice &&
        this.props.invoiceData.getInvoice.invoice &&
        JSON.parse(
          JSON.stringify(this.props.invoiceData.getInvoice.invoice)
        )) ||
      "";
    if (name === "openInvoiceDetailModal") {
      let lines = (invoiceDetails && invoiceDetails.lines) || [];

      let subTotal = 0;

      lines.map((line, i) => {
        line.id = uuidv1();
        line.checked = false;

        if (line.startTime && line.startTime != "0") {
          line.startTime *= 1000;
        }
        if (line.endTime && line.endTime != "0") {
          line.endTime *= 1000;
        }

        line.amount = Number(line.amount).toFixed(2) || 0.0;

        subTotal = Number(subTotal) + Number(line.amount);
        return line;
      });
      this.setState({ lines, subTotal: Number(subTotal).toFixed(2) });
    }
  };

  //Draft > Edit
  draftEditInvoice = async () => {
    let { tran, advancedListEdit, selectedGuid , supplierGuid} = this.state;
    if (selectedGuid) {
      this.setState({ ...this.state, resetPage: false }, () => {
        this.props.history.push("/invoice-edit", {
          tran: tran,
          advancedListEdit: advancedListEdit,
          selectedGuid: selectedGuid,
          supplierGuid: supplierGuid,
          page: this.state.page
        });
      });
    }
  };

  //Draft > New
  addNewInvoice = () => {
    let type = this.state.showInvoiceTallisTabPane.toLowerCase() || "draft";
    this.props.history.push("/add-new-invoice", {
      type,
      page: this.state.page,
    });
  };

  //deleting the Invocie
  deleteInvoice = async () => {
    let { tran, multipleTrans, selectedGuid } = this.state;
    let _trans = "";
    if (multipleTrans.length > 0) {
      if (multipleTrans.length == 1) {
        _trans = multipleTrans[0];
      } else {
        toast.error("Only One Invoice can be Delete at a Time!");
      }
    } else {
      _trans = tran;
    }

    if (selectedGuid) {
      this.setState({ isLoading: true });
      await this.props.deleteInvoice(selectedGuid); //to deleteinvoice
      //success case of Delete Invoice
      if (this.props.invoiceData.deleteInvoiceSuccess) {
        toast.success(this.props.invoiceData.deleteInvoiceSuccess);
        // When deleting an invoice --- Can it just highlight the invoice above the deleted one?
        let {
          getInvoicesList,
          multipleTrans,
          activeInvoice,
          activeInvoiceTallis,
          showInvoiceTallisTabPane,
          invoiceTallies,
          tran,
        } = this.state;

        if (getInvoicesList.length === 1) {
          await this.clearStates();
          //decrease the tallies count also
          invoiceTallies.map((t, i) => {
            if (
              showInvoiceTallisTabPane.toLocaleLowerCase() ===
              t.type.toLocaleLowerCase()
            ) {
              if (t.count === 1) {
                t.count = 0;
              } else {
                t.count = t.count - 1;
              }
            }
            return t;
          });
          this.setState({
            activeInvoiceTallis,
            showInvoiceTallisTabPane,
            invoiceTallies,
          });
        } else if (getInvoicesList.length > 1) {
          if (_trans === tran) {
            //when user delete the current selected invoice
            //there are two cases if the user deletes the first invoice in the list  then active the very next otherwise highlight invoice above the deleted invoice
            let foundIndex = getInvoicesList.findIndex(
              (l) => l.id === activeInvoice
            );
            if (foundIndex != -1 && foundIndex === 0) {
              let inv = getInvoicesList[foundIndex + 1];
              if (inv) {
                await this.getInvoice(inv);
              }
            } else {
              let inv = getInvoicesList[foundIndex - 1];
              if (inv) {
                await this.getInvoice(inv);
              }
            }
            let list = getInvoicesList.filter((l) => l.tran != _trans);
            //decrease the tallies count also
            invoiceTallies.map((t, i) => {
              if (
                showInvoiceTallisTabPane.toLocaleLowerCase() ===
                t.type.toLocaleLowerCase()
              ) {
                if (t.count === 1) {
                  t.count = 0;
                } else {
                  t.count = t.count - 1;
                }
              }
              return t;
            });

            this.setState({
              getInvoicesList: list,
              clonedGetInvoicesList: list,
              invoiceTallies,
              multipleTrans: [],
            });
          } else {
            //when user delete other invoice by checking the check box
            let list = getInvoicesList.filter((l) => l.tran != _trans);
            //decrease the tallies count also
            invoiceTallies.map((t, i) => {
              if (
                showInvoiceTallisTabPane.toLocaleLowerCase() ===
                t.type.toLocaleLowerCase()
              ) {
                if (t.count === 1) {
                  t.count = 0;
                } else {
                  t.count = t.count - 1;
                }
              }
              return t;
            });

            this.setState({
              getInvoicesList: list,
              clonedGetInvoicesList: list,
              invoiceTallies,
              multipleTrans: [],
            });
          }
        }
      }
      //error case of Delete Invoice
      if (this.props.invoiceData.deleteInvoiceError) {
        handleAPIErr(this.props.invoiceData.deleteInvoiceError, this.props);
      }
      this.props.clearInvoiceStates();
      this.setState({ isLoading: false });
    }
  };

  //approve Invoice
  approveInvoice = async () => {
    let {  multipleSelectedGuid, selectedGuid } = this.state;
    if ( selectedGuid || ( multipleSelectedGuid && multipleSelectedGuid.length > 0)) {
      let _trans = "";
      if (multipleSelectedGuid.length > 0) {
        _trans = multipleSelectedGuid;
      }else {
        _trans = [selectedGuid];
      }

      this.setState({
        isLoading: true,
      });

      if (this.state.allSelected) {
        await this.props.approveInvoice({
          selectAll: "Y",
          invoiceType: this.state.showInvoiceTallisTabPane,
          search: this.state.invoicesListSearch,
          teamInvoices: this.state.teamInvcCheck,
        });
      } else {
        await this.props.approveInvoice({ guid: _trans }); // approve invoice
      }

      //success case of approve invoice
      if (this.props.invoiceData.approveInvoiceSuccess) {
        // toast.success(this.props.invoiceData.approveInvoiceSuccess);
        await this.getInvoiceTallies(this.state.showInvoiceTallisTabPane, true); //to refresh the list
      }
      //error case of approve invoice
      if (this.props.invoiceData.approveInvoiceError) {
        handleAPIErr(this.props.invoiceData.approveInvoiceError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearInvoiceStates();
    } else {
      toast.error("Please Select Invoice First!");
    }
  };

  //decline Invoice
  declineInvoice = async (reason) => {
    let { selectedGuid, tran, multipleTrans } = this.state;
    let _trans = "";
    if (multipleTrans.length > 0) {
      if (multipleTrans.length == 1) {
        _trans = multipleTrans[0];
      } else {
        toast.error("Only One Invoice can be Declined at a Time!");
      }
    } else {
      _trans = tran;
    }

    if (_trans) {
      this.setState({
        isLoading: true,
      });
      await this.props.declineInvoice(selectedGuid, reason); // decline invoice
      //success case of decline invoice
      if (this.props.invoiceData.declineInvoiceSuccess) {
        // toast.success(this.props.invoiceData.declineInvoiceSuccess);
        await this.getInvoiceTallies(this.state.showInvoiceTallisTabPane, true); //to refresh the list
      }
      //error case of decline invoice
      if (this.props.invoiceData.declineInvoiceError) {
        handleAPIErr(this.props.invoiceData.declineInvoiceError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearInvoiceStates();
    }
  };

  // sendForApprovalInvoice =>Draft -> send
  sendForApprovalInvoice = async () => {
    let { selectedGuid, multipleSelectedGuid } = this.state;

    let _trans = "";
    if (multipleSelectedGuid.length > 0) {
      _trans = multipleSelectedGuid;
    }else{
      _trans = [selectedGuid]
    }

    if (_trans) {
      this.setState({
        isLoading: true,
      });
      if (this.state.allSelected) {
        await this.props.sendForApprovalInvoice({
          selectAll: "Y",
          invoiceType: this.state.showInvoiceTallisTabPane,
          search: this.state.invoicesListSearch,
          teamInvoices: this.state.teamInvcCheck,
        });
      } else {
        await this.props.sendForApprovalInvoice({ guid: _trans }); // send For Approval invoice
      }
      //success case of send For Approval invoice
      if (this.props.invoiceData.sendForApprovalInvoiceSuccess) {
        this.setState({ activatedPage: null });
        // toast.success(this.props.invoiceData.sendForApprovalInvoiceSuccess);
        await this.getInvoiceTallies(this.state.showInvoiceTallisTabPane, true); //to refresh the list
      }
      //error case of send For Approval invoice
      if (this.props.invoiceData.sendForApprovalInvoiceError) {
        handleAPIErr(
          this.props.invoiceData.sendForApprovalInvoiceError,
          this.props
        );
      }
      this.setState({ isLoading: false });
      this.props.clearInvoiceStates();
    }
  };

  deleteInvoiceLine = (id) => {
    let { lines } = this.state;
    if (id) {
      let filteredInvoiceLines = lines.filter((p) => p.id != id);

      let subTotal = 0;

      filteredInvoiceLines.map((line, i) => {
        subTotal += Number(line.amount);

        return line;
      });
      this.setState({ lines: filteredInvoiceLines, subTotal });
    }
  };

  closeConfrimationModal = () => {
    this.setState({ isModalOpen: false });
  };

  openModalWithContent = (title, body, actionButtonName, onConfirm) => {
    this.setState({
      modalContent: { title, body, actionButtonName, onConfirm },
      isModalOpen: true,
    });
  };

  getMoveRecord = () => {
    let { multipleSelectedGuid } = this.state;
    return multipleSelectedGuid.length === 0 ? 1 : multipleSelectedGuid.length
  }

  onClickMoveInvoice = () => {
    this.openModalWithContent('Move', `The selected ${this.getMoveRecord()} Invoices will be moved to your drafts folder to modify and resend for approval.`, 'Save', this.actionClickMoveInvoice)
  }

  actionClickMoveInvoice = () => {
    this.moveInvoice();
  }

  //move invoice
  moveInvoice = async () => {
    let {  multipleSelectedGuid, selectedGuid } = this.state;
    if (selectedGuid || (multipleSelectedGuid && multipleSelectedGuid.length > 0)) {
      let _trans = "";
      if (multipleSelectedGuid.length > 0) {
        _trans = multipleSelectedGuid;
      }else{
        _trans = [selectedGuid];
      }

      this.setState({
        isLoading: true,
      });

      if (this.state.allSelected) {
        await this.props.moveInvoice({
          selectAll: "Y",
          invoiceType: this.state.showInvoiceTallisTabPane,
          search: this.state.invoicesListSearch,
          teamInvoices: this.state.teamInvcCheck,
        });
      } else {
        await this.props.moveInvoice({ guid: _trans }); // move invoice
      }
      //success case of move invoice
      if (this.props.invoiceData.moveInvoiceSuccess) {
        // toast.success(this.props.invoiceData.moveInvoiceSuccess);
        await this.getInvoiceTallies(this.state.showInvoiceTallisTabPane, true); //to refresh the list
      }
      //error case of move invoice
      if (this.props.invoiceData.moveInvoiceError) {
        handleAPIErr(this.props.invoiceData.moveInvoiceError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearInvoiceStates();
    } else {
      toast.error("Please Select Invoice First!");
    }
  };

  //add/update invoice Lines
  getNewORUpdatedInvoiceLine = async (invoiceLine) => {
    let { lines } = this.state;

    if (invoiceLine.id) {
      //update case

      var foundIndex = lines.findIndex((p) => p.id == invoiceLine.id);

      // var data = lines.find(p => p.id == invoiceLine.id);
      if (foundIndex != -1) {
        invoiceLine.customFields = lines[foundIndex].customFields || []; //to add custome fields
      }
      if (invoiceLine.customFields && invoiceLine.customFields.length > 0) {
        invoiceLine.customFields.map((c, i) => {
          if (invoiceLine[c.prompt]) {
            return (c.value = invoiceLine[c.prompt]);
          }
        });
      }

      lines[foundIndex] = invoiceLine;
    } else {
      //add case
      invoiceLine.id = uuidv1();
      invoiceLine.checked = false;

      lines.push(invoiceLine);
    }
    this.setState({ lines }, () => {
      let subTotal = Number(0);
      this.state.lines.map((p, i) => {
        subTotal += Number(p.amount);
      });
      this.setState({ subTotal });
    });
  };

  openInvoiceDetailModal = async () => {
    let { tran, selectedGuid } = this.state;
    this.setState({ isLoading: true });
    await this.getInvoiceLines(selectedGuid)
    if (tran) {
      this.openModal("openInvoiceDetailModal");
    }
  };

  //update invoice
  updateInvoiceLines = async () => {
    let { selectedGuid } = this.state;
    if (selectedGuid) {
      let { lines } = this.state;

      lines.map((l, i) => {
        l.description = l.description;
        l.chartSort = l.chartSort.toUpperCase();
        l.chartCode = l.chartCode.toUpperCase();
        return l;
      });

      this.setState({
        isLoading: true,
      });

      let data = {
        guid: selectedGuid,
        invoiceLines: lines,
      };
      await this.props.updateInvoiceLines(data); // update Invoice Lines

      //success case of update Invoice Lines
      if (this.props.invoiceData.updateInvoiceLinesSuccess) {
        // toast.success(this.props.invoiceData.updateInvoiceLinesSuccess);

        //get updated invoice after editing
        let { activeInvoice, tran } = this.state;
        await this.getInvoice(
          {
            id: activeInvoice,
            tran,
            guid: selectedGuid
          },
          true
        );
        //end
      }
      //error case of update Invoice Lines
      if (this.props.invoiceData.updateInvoiceLinesError) {
        handleAPIErr(
          this.props.invoiceData.updateInvoiceLinesError,
          this.props
        );
      }
      this.setState({ isLoading: false, openInvoiceDetailModal: false });
    } else {
      toast.error("Please select Invoice First!");
    }
  };

  //upldate po-lines according to multiple change modal
  handleMultipleChanges = (data) => {
    let { lines } = this.state;

    let flagIsEmpty = false;

    // data.trackingCodes.map((f, i) => {
    //   if (f.value.trim() == "") {
    //     flagIsEmpty = true;
    //   }
    // });

    lines.map((p, i) => {
      if (p.checked) {
        if (data.chartSort) {
          p.chartSort = data.chartSort;
        }
        if (data.chartCode) {
          p.chartCode = data.chartCode || "";
        }
        if (data.trackingCodes && data.trackingCodes.length > 0) {
          // p.flags = data.trackingCodes;
          p.flags.map((m) => {
            data.trackingCodes.map((l) => {
              if (l.value) {
                if (m.value != l.value && m.sequence === l.sequence) {
                  m.value = l.value;
                }
              }
            });
          });
        }
      }
      return p;
    });
    this.setState({ lines });
  };

  handleCheckboxesInInvoiceDetails = (e, line) => {
    let lines = JSON.parse(JSON.stringify(this.state.lines));
    if (e.target.checked) {
      if (line === "all") {
        lines.map(async (l, i) => {
          l.checked = true;
          return l;
        });
      } else {
        lines.map(async (l, i) => {
          if (l.id === line.id) {
            l.checked = true;
          }
          return l;
        });
      }
    } else {
      if (line === "all") {
        lines.map(async (l, i) => {
          l.checked = false;
          return l;
        });
      } else {
        lines.map(async (l, i) => {
          if (l.id === line.id) {
            l.checked = false;
          }
          return l;
        });
      }
    }

    this.setState({
      lines,
    });
  };

  //exclude filters
  handleExclude = (e) => {
    let name = e.target.name;
    let checked = e.target.checked;
    this.setState(
      {
        [name]: checked,
      },
      async () => {
        let { zero, close, fullyApproved } = this.state;

        let check = !zero && !close && !fullyApproved ? true : false; //all checkboxes are uncheck
        const clonedGetInvoicesList = JSON.parse(
          JSON.stringify(this.state.clonedGetInvoicesList)
        );

        if (check) {
          //all checkboxes are uncheck
          this.setState(
            {
              getInvoicesList: clonedGetInvoicesList,
              filteredInvoicesList: clonedGetInvoicesList,
            },
            () => {
              this.handleSortInvoiceLists(this.state.sortFilterInvc);
            }
          );
        } else {
          //checkbox chcek case
          let filterdData = [];
          let excludeFilters = [];
          if (zero) {
            excludeFilters = ["close", "fullyApproved", ""];
          }
          if (close) {
            excludeFilters = ["zero", "fullyApproved", ""];
          }
          if (fullyApproved) {
            excludeFilters = ["zero", "close", ""];
          }
          if (zero && close) {
            excludeFilters = ["fullyApproved", ""];
          }

          if (zero && fullyApproved) {
            excludeFilters = ["close", ""];
          }

          if (close && fullyApproved) {
            excludeFilters = ["zero", ""];
          }

          if (close && fullyApproved && zero) {
            excludeFilters = [""];
          }

          excludeFilters.map((f, i) => {
            let InvoiceListFilterdData = [];

            InvoiceListFilterdData = clonedGetInvoicesList.filter((c) => {
              // if (c.excludeStatus.toUpperCase()) {
              return c.excludeStatus.toUpperCase() === f.toUpperCase();
              // }
            });
            filterdData.push(...InvoiceListFilterdData);
          });

          this.setState(
            {
              getInvoicesList: filterdData,
              filteredInvoicesList: filterdData,
            },
            () => {
              this.handleSortInvoiceLists(this.state.sortFilterInvc);
            }
          );
        }
      }
    );
  };

  //approvals filter
  handleApprovalsFilters = (e, obj) => {
    let checked = e.target.checked;
    obj.checked = checked;

    let approvalsGroups = this.state.approvalsGroups;
    let foundIndex = approvalsGroups.findIndex((a) => a.id == obj.id);
    approvalsGroups[foundIndex] = obj;

    this.setState({
      approvalsGroups,
    });
    let check = false;
    let count = 0;
    approvalsGroups.map((a, i) => {
      if (!a.checked) {
        count += 1;
      }
    });
    if (approvalsGroups.length === count) {
      check = true;
    }
    const clonedGetInvoicesList = JSON.parse(
      JSON.stringify(this.state.clonedGetInvoicesList)
    );

    if (check) {
      //all checkboxes are uncheck
      this.setState(
        {
          getInvoicesList: clonedGetInvoicesList,
          filteredInvoicesList: clonedGetInvoicesList,
        },
        () => {
          this.handleSortInvoiceLists(this.state.sortFilterInvc);
        }
      );
    } else {
      let filterdData = [];

      approvalsGroups.map((a, i) => {
        let InvoiceListFilterdData = [];
        if (a.checked) {
          InvoiceListFilterdData = clonedGetInvoicesList.filter((c) => {
            return (
              c.approvalGroup &&
              c.approvalGroup.toUpperCase() === a.groupName.toUpperCase()
            );
          });
        }
        filterdData.push(...InvoiceListFilterdData);
      });

      this.setState(
        {
          getInvoicesList: filterdData,
          filteredInvoicesList: filterdData,
        },
        () => {
          this.handleSortInvoiceLists(this.state.sortFilterInvc);
        }
      );
    }
  };

  // move to previous invoice
  moveToPrevInvoice = async () => {
    let { getInvoicesList, activeInvoice } = this.state;
    let foundIndex = getInvoicesList.findIndex((l) => l.id === activeInvoice);

    if (foundIndex != -1 && foundIndex != 0) {
      let inv = getInvoicesList[foundIndex - 1];
      if (inv) {
        await this.getInvoice(inv);
      }
    }
  };

  // move to next invoice
  moveToNextInvoice = async () => {
    let { getInvoicesList, activeInvoice } = this.state;
    let foundIndex = getInvoicesList.findIndex((l) => l.id === activeInvoice);

    if (foundIndex != -1) {
      let inv = getInvoicesList[foundIndex + 1];
      if (inv) {
        await this.getInvoice(inv);
      }
    }
  };

  handleCheckbox = (e, data) => {
    this.setState({ checked: !this.state.checked });
    let { getInvoicesList, multipleTrans, multipleSelectedGuid } = this.state;
    let { name, checked } = e.target;
    let allSelected = false;
    if (data === "allCheck" && name === "checkboxAll") {
      let multipleTransCopy = [];
      let multipleGuidCopy = [];
      if (checked) {
        allSelected = true;
        getInvoicesList.map((m) => {
          m.checked = true;
          multipleTransCopy.push(m.tran);
          multipleGuidCopy.push(m.guid);
          return m;
        });
      } else {
        allSelected = false;
        getInvoicesList.map((m) => {
          m.checked = false;
          return m;
        });
      }
      multipleTrans = [...multipleTransCopy];
      multipleSelectedGuid = [...multipleGuidCopy];
    } else {
      if (checked) {
        getInvoicesList.map((invc, i) => {
          if (data.id === invc.id) {
            invc.checked = true;
          }
          return invc;
        });
        multipleTrans.push(data.tran);
        multipleSelectedGuid.push(data.guid);
      } else {
        getInvoicesList.map(async (invc, i) => {
          if (data.id === invc.id) {
            invc.checked = false;
          }
          return invc;
        });
        let filteredMultiTrans = multipleTrans.filter((t) => t != data.tran);
        let filteredMultiGuid = multipleSelectedGuid.filter(
          (t) => t != data.guid
        );
        multipleTrans = filteredMultiTrans;
        multipleSelectedGuid = filteredMultiGuid;
      }
    }

    this.setState({
      getInvoicesList,
      multipleTrans,
      allSelected,
      multipleSelectedGuid
    });
  };

  clonedBatchListGenerate = () => {
    let batchListOptions = [];
    let batchList = [];
    let clonedBatchList = [];
    batchList = this.props.setup.getBatchList || [];
    batchList.map((b) =>
      batchListOptions.push({
        label: b.description + " (" + b.batchNo + ")",
        value: b.batchNo,
      })
    )

    clonedBatchList = batchList.map((batch) => ({
      ...batch,
      checked: false,
    }));
    this.setState({ batchList , clonedBatchList, batchListOptions});
  }

  getBtachList = async () => {
    await this.props.getBtachList("Invoices");
    if (this.props.setup.getBatchListSuccess) {
      // toast.success(this.props.setup.getBatchListSuccess)
     await this.clonedBatchListGenerate()

    }
    if (this.props.setup.getBatchListError) {
      handleAPIErr(this.props.setup.getBatchListError, this.props);
    }
    this.props.clearSetupStates();
    this.setState({   });
  };

  handleChangeBatchFields = (e, batch, index) => {
    let { batchList } = this.state;
    let { name, value } = e.target;
    batch[name] = value;
    batchList[index] = batch;
    this.setState({ batchList });
  };

  handleBatchCheckbox = (e, bNo) => {
    let { getInvoicesList, filteredInvoicesList, clonedBatchList, selectedBatchNos } = this.state;

    let batchNo = "";

    clonedBatchList = clonedBatchList.map((batch) =>
      batch.batchNo === bNo ? { ...batch, checked: e.target.checked } : batch
    );

    // Update the selectedBatchNos array based on the checkbox state
    selectedBatchNos = e.target.checked ? [...selectedBatchNos, bNo] : selectedBatchNos.filter((batch) => batch !== bNo);

    const clonedGetInvoicesList = JSON.parse(
      JSON.stringify(this.state.clonedGetInvoicesList)
    );

    if (e.target.checked) {
      batchNo = bNo;

      let invcListFilterdLst = clonedGetInvoicesList.filter((c) => {
        return Number(c.batchNo) === Number(bNo);
      });

      getInvoicesList = invcListFilterdLst;
      filteredInvoicesList = invcListFilterdLst;
    } else {
      //uncheck checkbox
      getInvoicesList = clonedGetInvoicesList;
      filteredInvoicesList = clonedGetInvoicesList;
    }
    this.setState(
      {
        batchNo,
        getInvoicesList,
        filteredInvoicesList,
        selectedBatchNos,
        clonedBatchList
      },
      () => this.handleSortInvoiceLists(this.state.sortFilterInvc)
    );
  };

  insertBatch = async (event) => {
    let { batchList } = this.state;

    let obj = _.maxBy(batchList, "batchNo"); //to fine the highest batch no
    let batchNo = Number(obj ? obj.batchNo : "") + 1;
    batchList = [
      ...batchList,
      {
        batchNo,
        description: "",
        type: "AP",
        notes: "",
        insertBatch: true,
      },
    ];

    this.setState({ batchList });
  };

  deleteBatch = async () => {
    let { batchList, batchNo, getInvoicesList, filteredInvoicesList } =
      this.state;

    let batch = batchList.find((b) => b.batchNo === batchNo);
    if (batch && batch.insertBatch) {
      //just remove the newly inserted batch, no need to call API
      batchList = batchList.filter((c) => c.batchNo !== batchNo);
      batchNo = "";

      this.setState({ batchList, batchNo });
    } else if (batchNo === 0 || batchNo) {
      this.setState({ isLoading: true });
      await this.props.deleteBatch(batchNo);
      if (this.props.setup.deleteBatchSuccess) {
        toast.success(this.props.setup.deleteBatchSuccess);

        batchList = batchList.filter((c) => c.batchNo !== batchNo);
        batchNo = "";

        const clonedGetInvoicesList = JSON.parse(
          JSON.stringify(this.state.clonedGetInvoicesList)
        );

        getInvoicesList = clonedGetInvoicesList;
        filteredInvoicesList = clonedGetInvoicesList;
      }
      if (this.props.setup.deleteBatchError) {
        handleAPIErr(this.props.setup.deleteBatchError, this.props);
      }
      this.props.clearSetupStates();
      this.setState({
        isLoading: false,
        batchList,
        batchNo,
        getInvoicesList,
        filteredInvoicesList,
      });
    } else {
      toast.error("Please Select Batch First!");
    }
  };

  addUpdateBatch = async (e, batch, index) => {
    let { batchList } = this.state;

    let { name, value } = e.target;

    batch[name] = value;

    let bch = batchList[index];

    if (bch.insertBatch) {
      //insert new batch case

      let { batchNo } = batch;

      batchNo = batchNo.toString();
      batchNo = batchNo.trim();
      batchNo = batchNo ? Number(batchNo) : "";

      if (batchNo === 0 || batchNo) {
        let exists = batchList.find(
          (b) => b.batchNo === batchNo && !b.insertBatch
        ); //in case if batch no is already exists

        if (!exists) {
          this.setState({ isLoading: true });

          await this.props.insertBatch({ batch });

          if (this.props.setup.insertBatchSuccess) {
            toast.success(this.props.setup.insertBatchSuccess);
            batch.insertBatch = false;
          }
          if (this.props.setup.insertBatchError) {
            handleAPIErr(this.props.setup.insertBatchError, this.props);
          }
          this.props.clearSetupStates();

          this.setState({ isLoading: false });
        } else {
          toast.error("Batch No Already Exists!");
        }
      } else {
        toast.error("Please Enter Btach No!");
      }
    } else {
      //update batch case

      this.setState({ isLoading: true });

      await this.props.updateBatch({ batch });
      if (this.props.setup.updateBatchSuccess) {
        toast.success(this.props.setup.updateBatchSuccess);
        batchList[index] = batch;
      }
      if (this.props.setup.updateBatchError) {
        handleAPIErr(this.props.setup.updateBatchError, this.props);
      }

      this.props.clearSetupStates();
      this.setState({ isLoading: false, batchList });
    }
  };

  zoomIn = async () => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");

    let { scaling } = this.state;

    let { scale, dropdownZoomingValue, zoom } = zoomIn(scaling);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        localStorage.setItem("invoiceZoom", zoom);

        if (
          scaling == 2.5 ||
          scaling == 2.2 ||
          scaling == 1.9 ||
          scaling == 1.6 ||
          scaling == 1.3
        ) {
          if ($(window).width() >= 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  zoomOut = async () => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");

    let { scaling } = this.state;

    let { scale, dropdownZoomingValue, zoom } = zoomOut(scaling);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        localStorage.setItem("invoiceZoom", zoom);
        if (
          scaling == 2.5 ||
          scaling == 2.2 ||
          scaling == 1.9 ||
          scaling == 1.6 ||
          scaling == 1.3
        ) {
          if ($(window).width() >= 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  handleDropdownZooming = async (data) => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");

    let value = data.value;

    localStorage.setItem("invoiceZoom", value);

    let { scale, dropdownZoomingValue } = handleDropdownZooming(value);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        if (
          value == "25%" ||
          value == "20%" ||
          value == "15%" ||
          value == "10%" ||
          value == "5%"
        ) {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  handleRightSidebar = () => {
    this.setState((prevState, props) => ({
      toggleRightSidebar: !prevState.toggleRightSidebar,
    }));
  };

  handleHorizontalArrow = () => {
    $(".invoice_pdf_new").addClass("invoice_carousel_pdf");

    $(".invoice_pdf_new").removeClass("full_screen_convas");

    if ($(window).width() > 1500) {
      this.setState({
        scaling: 7,
        dropdownZoomingValue: { label: "100%", value: "100%" },
      });
    } else if ($(window).width() <= 1500) {
      this.setState({
        scaling: 3.1,
        dropdownZoomingValue: { label: "35%", value: "35%" },
      });
    }
  };

  handleHorizontalCross = () => {
    $(".mm_invoice_div").addClass("over_auto_remove");
    $(".invoice_pdf_new").addClass("full_screen_convas");

    var vw = $(window).width();
    var nav = $(".navbar.fixed-top").height();
    var underNav = $(".order-tabs").height();
    var wh = $(window).height();
    var pdfDiv = wh - nav;
    var wh = wh - nav - underNav;

    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);

    if ($(window).width() > 1500) {
      this.setState({
        scaling: 7,
        dropdownZoomingValue: { label: "100%", value: "100%" },
      });
    } else if ($(window).width() <= 1500) {
      this.setState({
        scaling: 3.1,
        dropdownZoomingValue: { label: "35%", value: "35%" },
      });
    }
  };

  onLoadSuccessPage = ({ numPages }) => {
    var vw = $(window).width();
    var vw = $(".side-attachments").width();
    var nav = $(".navbar.fixed-top").height();
    var underNav = $(".order-tabs").height();
    var wh = $(window).height();
    var h = wh - nav - 60;
    var rsb = wh - nav - underNav - 20;
    // var pdfDiv = wh - nav - underNav - 80
    var pdfDiv = wh - nav;
    var wh = wh - nav - underNav;
    $("#order--dynamic--height").css("height", h);
    $(".side-attachments,.side-attachments-2").css("height", rsb);
    // $('#maped_image').css('height', pdfDiv)
    $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);
  };

  onDocumentLoadSuccess = (data, index) => {
    let numPages = data.numPages;
    this.setState({ numPages, pageNumber: 1 }, () => this.settPreviewArrows());
  };

  goToPrevPage = async () => {
    let { pageNumber } = this.state;
    if (pageNumber - 1 >= 1) {
      this.setState({ pageNumber: pageNumber - 1 });
    }
  };

  goToNextPage = () => {
    let { pageNumber, numPages } = this.state;
    if (pageNumber + 1 <= numPages) {
      this.setState({ pageNumber: pageNumber + 1 });
    }
  };

  /**
   * Change the current attachment
   * @param {'next'|'prev'} action Determines if the previous or next attachment should be loaded
   */
  onSlideChange = (action) => {
    const {
      previewableAttachments,
      currentAttachmentIndex,
      previewList,
    } = this.state;
    if (previewList.length === 0) {
      return;
    }
    let newAttachmentIndex = action === 'next' ? currentAttachmentIndex + 1 : currentAttachmentIndex - 1;
    if (newAttachmentIndex < 0 || newAttachmentIndex >= previewableAttachments.length) {
      // As these two can't happen, so we just ignore
      return;
    }
    const isPreviewLoaded = previewList[newAttachmentIndex];
    if (previewList.length === previewableAttachments.length || isPreviewLoaded !== undefined) {
      // if all the previewableAttachments or the requestetd one already loaded, don't fetch
      this.setState({ currentAttachmentIndex: newAttachmentIndex });
      return;
    }
    this.setState({
      isLoading: true,
      currentAttachmentIndex: -1
    }, () => this.loadNextPreview(
      previewList,
      previewableAttachments,
      newAttachmentIndex
    )
    );
  };

  loadNextPreview(previewList, previewableAttachments, newAttachmentIndex) {
    const attachmentRecordId = previewableAttachments[newAttachmentIndex].recordID;
    let newPreviews = [...previewList];
    InvoiceActions.getAttachment(attachmentRecordId)
      .then((attachment) => {
        if (isValidString(attachment.file)) {
          const primaryDocument = attachment?.primaryDocument || previewableAttachments[newAttachmentIndex]?.primaryDoc || "N";
          toast.success(attachment.description); 
          newPreviews.push({
            file: attachment.file,
            primaryDocument: primaryDocument,
            documentPresignedURL: attachment.documentPresignedURL,
          });
        } else {
          toast.error(attachment.description);
        }
        this.setState({
          previewList: newPreviews,
        }, () => this.onNewAttachmentLoaded(newAttachmentIndex));
      })
      .catch((error) => {
        toast.error(error?.message || 'Unable to load attachment');
      }).finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  onNewAttachmentLoaded = (newAttachmentIndex) => {
    this.setState(
      { 
        currentAttachmentIndex: newAttachmentIndex,
        pageNumber: 1, rotate: 0
      },
      () => this.settPreviewArrows()
    );
  }

  settPreviewArrows = () => {
    const {
      previewableAttachments,
      currentAttachmentIndex
    } = this.state;
    const nextIndex = currentAttachmentIndex + 1;
    const previousDailyTimes = currentAttachmentIndex - 1;
    if (previousDailyTimes < 0) {
      $(".carousel-control-prev").hide();
      $(".carousel-control-next").show();
    } else if (nextIndex >= previewableAttachments.length) {
      $(".carousel-control-prev").show();
      $(".carousel-control-next").hide();
    } else {
      $(".carousel-control-prev").show();
      $(".carousel-control-next").show();
    }
  };

  handlePDFRotate = () => {
    this.setState({ rotate: this.state.rotate + 90 });
  };

  //Regenerate Signature
  regenerateSignatures = async () => {
    let { multipleTrans, tran, selectedGuid,multipleSelectedGuid } = this.state;
    if (multipleTrans) {
      this.setState({
        isLoading: true,
      });
      if (multipleTrans.length === 0) {
        await this.props.regenerateSignatures(selectedGuid ); // regenerate signature
      } else {
        await this.props.regenerateSignatures(multipleSelectedGuid); // regenerate signature
      }
      //success case of regenerate signature
      await this.handleSucessStateRegenerateSignatures(selectedGuid);
      //error case of regenerate signature
      if (this.props.invoiceData.regenerateSignatureError) {
        handleAPIErr(
          this.props.invoiceData.regenerateSignatureError,
          this.props
        );
      }
      this.setState({ isLoading: false });
      this.props.clearInvoiceStates();
    } else {
      toast.error("Please Select Invoice First!");
    }
  };

  handleSucessStateRegenerateSignatures = async (selectedGuid) => {
    if (this.props.invoiceData.regenerateSignatureSuccess) {
      toast.success(this.props.invoiceData.regenerateSignatureSuccess);
      //call get invoice API to get the updated Previews List
      await this.props.getInvoiceSummary(selectedGuid); // get Invocie
      //success case of getInvoice
      if (this.props.invoiceData.getInvoiceSuccess) {
        // toast.success(this.props.invoiceData.getInvoiceSuccess);
        let invoiceDetails =
          (this.props.invoiceData.getInvoice &&
            this.props.invoiceData.getInvoice.invoice &&
            JSON.parse(
              JSON.stringify(this.props.invoiceData.getInvoice.invoice)
            )) ||
          "";
        let previewList =
          (this.props.invoiceData &&
            this.props.invoiceData.getInvoice &&
            this.props.invoiceData.getInvoice.previewList) ||
          [];

        let preview = (invoiceDetails && invoiceDetails.preview) || "";

        this.setState({
          preview,
          previewList,
        });
      }
      //error case of get invoice
      if (this.props.invoiceData.getInvoiceError) {
        handleAPIErr(this.props.invoiceData.getInvoiceError, this.props);
      }
      this.props.clearInvoiceStates();
    }
  };
  //call get invocie list API
  toggleTeamIcon = (check) => {
    localStorage.setItem("teamInvoice", check);
    this.setState({ viewTeam: check }, () => {
      let { activeInvoiceTallis, showInvoiceTallisTabPane } = this.state;
      let obj = {
        id: activeInvoiceTallis,
        type: showInvoiceTallisTabPane,
      };
      this.checkPOList_API(obj);
    });
  };

  openPostModal = () => {
    let { multipleTrans } = this.state;

    if (multipleTrans.length > 0) {
      this.openModal("openPostModal");
    } else {
      toast.error("Please Select Invoice First!");
    }
  };

  postInvoice = async (data) => {
    let { multipleTrans, multipleSelectedGuid} = this.state;
    let { period, reportID, generateReport } = data;

    let obj = {
      guid: multipleSelectedGuid,
      period,
      reportID,
      generateReport: generateReport ? "Y" : "N",
    };
    this.setState({ isLoading: true });
    await this.props.postInvoice(obj);
    if (this.props.invoiceData.postInvoiceSuccess) {
      toast.success(this.props.invoiceData.postInvoiceSuccess);

      let jsonData = this.props.invoiceData.postInvoice.reportJSON || "";
      let reportFile = this.props.invoiceData.postInvoice.reportTemplate || "";

      if (jsonData && reportFile) {
        localStorage.setItem("reportFile", reportFile);
        localStorage.setItem("jsonData", jsonData);
        localStorage.setItem("key", "test");
        var path =
          window.location.protocol +
          "//" +
          window.location.host +
          "/report-view";

        window.open(path, "_blank");
      }
      this.closeModal("openPostModal");
    }
    if (this.props.invoiceData.postInvoiceError) {
      handleAPIErr(this.props.invoiceData.postInvoiceError, this.props);
    }
    this.props.clearInvoiceStates();
    this.setState({ isLoading: false });
  };

  hanldeExport = (type) => {
    if (type === "EXCEL") {
      this.exportInvoice();
    } else if (type === "TPH") {
      this.exportTPH();
    } else {
      //tax invoice case
      this.exportTaxInvoice();
    }
  };

  balanceTax = async () => {
    let { multipleTrans, multipleSelectedGuid } = this.state;

    if (multipleTrans.length > 0) {
      this.setState({ isLoading: true });

      if (this.state.allSelected) {
        await this.props.balanceTax({
          selectAll: "Y",
          invoiceType: this.state.showInvoiceTallisTabPane,
          search: this.state.InvoiceListSearch,
          teamInvoices: this.state.teamInvcCheck,
        });
      } else {
        await this.props.balanceTax({ guid: multipleSelectedGuid });
      }

      if (this.props.invoiceData.balanceTaxSuccess) {
        toast.success(this.props.invoiceData.balanceTaxSuccess);
      }
      if (this.props.invoiceData.balanceTaxError) {
        handleAPIErr(this.props.invoiceData.balanceTaxError, this.props);
      }
      this.props.clearInvoiceStates();
      this.setState({ isLoading: false });
    } else {
      toast.error("Please Select Invoice First!");
    }
  };

  exportInvoice = async () => {
    let { multipleTrans, multipleSelectedGuid } = this.state;

    if (multipleTrans.length > 0) {
      this.setState({ isLoading: true });

      if (this.state.allSelected) {
        await this.props.exportInvoice({
          selectAll: "Y",
          invoiceType: this.state.showInvoiceTallisTabPane,
          search: this.state.InvoiceListSearch,
          teamInvoices: this.state.teamInvcCheck,
        });
      } else {
        await this.props.exportInvoice({ guid: multipleSelectedGuid });
      }

      if (this.props.invoiceData.exportInvoiceSuccess) {
        toast.success(this.props.invoiceData.exportInvoiceSuccess);

        let obj = {
          contentType: "application/vnd.ms-excel",
          attachment: this.props.invoiceData.exportInvoice || "",
        };
        downloadAttachments(obj, "invoice");
      }
      if (this.props.invoiceData.exportInvoiceError) {
        handleAPIErr(this.props.invoiceData.exportInvoiceError, this.props);
      }
      this.props.clearInvoiceStates();
      this.setState({ isLoading: false });
    } else {
      toast.error("Please Select Invoice First!");
    }
  };

  exportTPH = async () => {
    let { multipleTrans, multipleSelectedGuid } = this.state;

    if (multipleTrans.length > 0) {
      this.setState({ isLoading: true });

      if (this.state.allSelected) {
        await this.props.exportTPH({
          selectAll: "Y",
          invoiceType: this.state.showInvoiceTallisTabPane,
          search: this.state.InvoiceListSearch,
          teamInvoices: this.state.teamInvcCheck,
        });
      } else {
        await this.props.exportTPH({ guid: multipleSelectedGuid });
      }

      if (this.props.invoiceData.exportTPHSuccess) {
        toast.success(this.props.invoiceData.exportTPHSuccess);
      }
      if (this.props.invoiceData.exportTPHError) {
        handleAPIErr(this.props.invoiceData.exportTPHError, this.props);
      }
      this.props.clearInvoiceStates();
      this.setState({ isLoading: false });
    } else {
      toast.error("Please Select Invoice First!");
    }
  };

  exportTaxInvoice = async () => {
    let { multipleTrans, multipleSelectedGuid } = this.state;

    if (multipleTrans.length > 0) {
      this.setState({ isLoading: true });

      if (this.state.allSelected) {
        await this.props.exportTaxInvoice({
          selectAll: "Y",
          invoiceType: this.state.showInvoiceTallisTabPane,
          search: this.state.InvoiceListSearch,
          teamInvoices: this.state.teamInvcCheck,
        });
      } else {
        await this.props.exportTaxInvoice({ guid: multipleSelectedGuid });
      }

      if (this.props.invoiceData.exportTaxInvoiceSuccess) {
        toast.success(this.props.invoiceData.exportTaxInvoiceSuccess);
        let exportTaxInvoice = this.props.invoiceData.exportTaxInvoice || [];

        exportTaxInvoice.map((e, i) => {
          let obj = {
            contentType: "application/pdf",
            isDownload: true,
            ...e,
          };
          downloadAttachments(obj);
        });
      }
      if (this.props.invoiceData.exportTaxInvoiceError) {
        handleAPIErr(this.props.invoiceData.exportTaxInvoiceError, this.props);
      }
      this.props.clearInvoiceStates();
      this.setState({ isLoading: false });
    } else {
      toast.error("Please Select Invoice First!");
    }
  };

  openMoveBatchPopup = () => {
    let { multipleTrans } = this.state;
    if (multipleTrans.length > 0) {
      this.openModal("openMoveModal");
    } else {
      toast.error("Please Select Invoice First!");
    }
  };

  moveBatch = async (batchNo) => {
    let { multipleTrans, multipleSelectedGuid } = this.state;
    this.setState({ isLoading: true });
    await this.props.moveBatch({ guid: multipleSelectedGuid, batchNo });
    if (this.props.invoiceData.moveBatchSuccess) {
      toast.success(this.props.invoiceData.moveBatchSuccess);
    }
    if (this.props.invoiceData.moveBatchError) {
      handleAPIErr(this.props.invoiceData.moveBatchError, this.props);
    }
    this.props.clearInvoiceStates();
    this.setState({ isLoading: false });
  };

  onImport = async (excelData, type) => {
    if (type === "List") {
      await this.importList(excelData);
    } else if (type === "Chq Request") {
      await this.importChqRequest(excelData);
    } else {
      //EP file
      await this.importEPFile(excelData);
    }
  };

  importChqRequest = async (excelData) => {
    this.setState({ isLoading: true });
    await this.props.importChqRequest(excelData);
    if (this.props.invoiceData.importChqReqSuccess) {
      toast.success(this.props.invoiceData.importChqReqSuccess);
      this.closeModal("openImportModal");
    }
    if (this.props.invoiceData.importChqReqError) {
      handleAPIErr(this.props.invoiceData.importChqReqError, this.props);
    }
    this.props.clearInvoiceStates();
    this.setState({ isLoading: false });
  };

  importList = async (excelData) => {
    this.setState({ isLoading: true });
    await this.props.importList(excelData);
    if (this.props.invoiceData.importListSuccess) {
      toast.success(this.props.invoiceData.importListSuccess);
      this.closeModal("openImportModal");
    }
    if (this.props.invoiceData.importListError) {
      handleAPIErr(this.props.invoiceData.importListError, this.props);
    }
    this.props.clearInvoiceStates();
    this.setState({ isLoading: false });
  };

  importEPFile = async (excelData) => {
    this.setState({ isLoading: true });
    await this.props.importEPFile(excelData);
    if (this.props.invoiceData.importEPFileSuccess) {
      toast.success(this.props.invoiceData.importEPFileSuccess);
      this.closeModal("openImportModal");
    }
    if (this.props.invoiceData.importEPFileError) {
      handleAPIErr(this.props.invoiceData.importEPFileError, this.props);
    }
    this.props.clearInvoiceStates();
    this.setState({ isLoading: false });
  };

  getSupplier = async () => {
    const { supplierGuid, bankOptionsFetchedFor, isBankDetailsCollapsed } = this.state;
    const canFetchSupplier = isBankDetailsCollapsed === true && bankOptionsFetchedFor !== supplierGuid;
    if (canFetchSupplier) {
      this.setState({ isLoading: true });
      const supplierResponse = await SupplierActions.getSupplierBankDetails(supplierGuid);
      if (isSuccessRequest(supplierResponse)) {
        let bankDetails = supplierResponse.bankDetails || {};
        const toastMessage = getToastMessage(supplierResponse);
        toast.success(toastMessage);
        this.setState({
          bankDetails,
          bankOptionsFetchedFor: supplierGuid,
        });
      }
      if (isFailedRequest(supplierResponse)) {
        const toastMessage = getToastMessage(supplierResponse);
        toast.error(toastMessage);
      }
      this.props.clearSupplierStates();
    }
    this.setState({ isBankDetailsCollapsed: !isBankDetailsCollapsed, isLoading: false }); 
  }

  handleRowsSetting = (e) => {
    let { activeInvoiceTallis, showInvoiceTallisTabPane } = this.state;
    let obj = {
      id: activeInvoiceTallis,
      type: showInvoiceTallisTabPane,
    };
    localStorage.setItem("orderDPR", e.target.value); // no of display order pages

    this.setState({ displayRecords: e.target.value }, () => {
      this.getNewInvoiceList(obj);
    });
  };

  handlePageClick = ({ selected }) => {
    this.setState({ guid: null, activatedPage: null });
    let { activeInvoiceTallis, showInvoiceTallisTabPane } = this.state;

    let obj = {
      id: activeInvoiceTallis,
      type: showInvoiceTallisTabPane,
    };

    this.setState(
      { page: selected + 1, pageStart: "", pageEnd: "", totalInvoices: "" },
      () => {
        this.savePageState();
        this.getNewInvoiceList(obj);
      }
    );
  };

  savePageState = () => {
    const { page, invoicesListSearch } = this.state;
    this.props.setLastPage("invoices", {
      pageNumber: page,
      searchTerm: invoicesListSearch,
    });
  };

  holdInvoice = async () => {
    let {  multipleSelectedGuid, showPOTallisTabPane, selectedGuid } = this.state;
    if (selectedGuid || (multipleSelectedGuid && multipleSelectedGuid.length > 0)) {
      let _trans = "";
      if (multipleSelectedGuid.length > 0) {
        _trans = multipleSelectedGuid;
      }else {
        _trans = [selectedGuid];
      }
      this.setState({
        isLoading: true,
      });

      if (this.state.allSelected) {
        await this.props.holdInvoice({
          selectAll: "Y",
          invoiceType: this.state.showInvoiceTallisTabPane,
          search: this.state.InvoiceListSearch,
          teamInvoices: this.state.teamInvcCheck,
        });
      } else {
        await this.props.holdInvoice({ guid: _trans }); // hold Invoice
      }

      //success case of hold Invoice
      if (this.props.invoiceData.holdInvoiceSuccess) {
        toast.success(this.props.invoiceData.holdInvoiceSuccess);

        await this.getInvoiceTallies(showPOTallisTabPane, true); //to refresh the list
      }
      //error case of hold Invoice
      if (this.props.invoiceData.holdInvoiceError) {
        handleAPIErr(this.props.invoiceData.holdInvoiceError, this.props);
      }
      this.setState({ isLoading: false });
      this.props.clearInvoiceStates();
    } else {
      toast.error("Please select Invoice First!");
    }
  };

  //  check approval function
  //moving to next tab
  moveToNextApprover = async (comment, email) => {
    this.setState({ isLoading: true });
    let { tran, selectedGuid } = this.state;
    let data = {
      guid: selectedGuid,
      comment: comment,
      email,
    };
    if (tran) {
      await this.props.moveToNextApprover(data);
      if (this.props?.invoiceData?.moveToNextApproverSuccess) {
        toast.success(
          this.props.invoiceData.moveToNextApproverSuccess[0].description
        );

        this.getInvoiceTallies(this.state.showInvoiceTallisTabPane, true);
      }
      if (this.props.invoiceData.moveToNextApproverError) {
        handleAPIErr(
          this.props.invoiceData.moveToNextApproverError,
          this.props
        );
      }
      this.setState({ isLoading: false });
    }
  };
  moveToPrevApprover = async (comment, email) => {
    this.setState({ isLoading: true });
    let { tran, selectedGuid } = this.state;
    let data = {
      guid: selectedGuid,
      comment: comment,
      email,
    };
    if (tran) {
      await this.props.moveToPrevApprover(data);
      if (this.props.invoiceData.moveToPrevApproverSuccess) {
        toast.success(
          this.props.invoiceData.moveToPrevApproverSuccess[0].description
        );
        this.getInvoiceTallies(this.state.showInvoiceTallisTabPane, true);
      }
      if (this.props.invoiceData.moveToPrevApproverError) {
        handleAPIErr(
          this.props.invoiceData.moveToPrevApproverError,
          this.props
        );
      }
      this.setState({ isLoading: false });
    }
  };

  getClassName = () => {
    if(isSafari()){
      return "suppliers_list list__color-bottom-safari";
     } else {
      return "suppliers_list list__color-bottom";
     } 
  }

  //----------------------****Send Email Approver*****------------------------------

  resendEmail = async () => {
    this.setState({ isLoading: true });

    let { tran, selectedGuid, multipleSelectedGuid } = this.state;
    await this.props.resendEmail( multipleSelectedGuid.length > 0 ? multipleSelectedGuid : selectedGuid);
    if (this.props.invoiceData.resendEmailSuccess) {
      toast.success(this.props.invoiceData.resendEmailSuccess);
    }

    if (this.props.invoiceData.resendEmailError) {
      handleAPIErr(this.props.invoiceData.resendEmailError, this.props);
    }
    this.setState({ isLoading: false });
  };

  collapseIconsChanged = (name, res) => {
    let collapseIconObj = {
      ...this.state.collapseIcon,
      [name] : res
    } ;
    this.setState({ collapseIcon : collapseIconObj  })
  }

  handleTransferInvoices = async (newUser) => {
    this.setState({ isLoading: true });
    
     let params = {};

    if (this.state.allSelected) {
      params = {
        newUser,
        selectAll: "Y",
        invoiceType: this.state.showInvoiceTallisTabPane,
        search: this.state.invoicesListSearch,
        teamInvoices: this.state.viewTeam,
      };
      if (this.state.batchNo) {
        params['batchList'] = [this.state.batchNo];
      }

    } else {
      params = {
        newUser,
        guid: this.state.multipleSelectedGuid.length > 0
        ? this.state.multipleSelectedGuid
        : [this.state.selectedGuid],
      };
    }

    await this.props.transferInvoices(params);

    if (this.props.invoiceData?.transferInvoicesSuccess) {
      toast.success('Successfully transferred invoices');
    } else if (this.props.invoiceData.transferInvoicesError) {
      toast.error(this.props.invoiceData.transferInvoicesError);
    } else {
      toast.error("Failed to transfer invoices");
    }
    this.setState({ isLoading: false });
  }

  displayBankDetail(bankDetailItem, bankDetailKey) {
    const keyMappedLabel = {
      paymentType: "TYPE",
      accountName: "ACCOUNT NAME",
      bank: "BANK",
      sort: "SORT",
      accountNo: "ACC NO",
      suffix: "SUFFIX",
      iban: "IBAN",
      bankReference: "REF",
      currencyCode: "CURR CODE",
      swift: "SWIFT",
      countryCode: "CU CODE",
    };
    const bankDetailLabel = keyMappedLabel[bankDetailKey] || "";
    if (isValidString(bankDetailItem)) {
      return (
        <div className="uppercaseText">
          <strong>
            {bankDetailLabel}:
          </strong> &nbsp;
          {bankDetailItem}
        </div>
      );
    }
    return (<></>);
  }

  render() {
    let userType = localStorage.getItem("userType");
    userType = userType?.toLowerCase();

    let {
      isModalOpen, 
      modalContent,
      batchList,
      batchNo,
      showInvoiceTallisTabPane,
      activeInvoice,
      getInvoicesList,
      bankDetails,
      clonedBatchList
    } = this.state;

    let tab =
      (showInvoiceTallisTabPane && showInvoiceTallisTabPane.toLowerCase()) ||
      "";
    let approverNextPrevCheck = false;
    if (tab !== "draft" && tab !== "approved") {
      if (
        (tab === "pending" || tab === "approve") &&
        (userType === "admin" || userType === "sysadmin")
      ) {
        approverNextPrevCheck = true;
      }
    }
    let usePageLoading = localStorage.getItem("usePageLoading") || "N";
    usePageLoading = usePageLoading.toLowerCase();
    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <div className="dashboard">
          {/* top nav bar */}
          <Header
            props={this.props}
            invoices={true}
            toggleTeamIcon={this.toggleTeamIcon}
            viewTeam={this.state.viewTeam}
          />
          {/* end */}

          {/* body part */}

          <div className="dashboard_body_content dash__invoice--content">
            {/* top Nav menu*/}
            <TopNav />
            {/* end */}

            {/* side menu suppliers*/}
            <aside
              className="side-nav suppliers_side_nav mm_invoice_left_sidebar side__content--invoice"
              id="show-side-navigation1"
            >
              <div className="cus-arro-div2">
                <img
                  src="images/arrow-r.png"
                  className=" img-fluid cus-arro-r"
                  alt="user"
                />
              </div>
              <div className="search">
                <div className="controller-row">
                  <div className="controller-1">
                    <div className="form-group remember_check custom-checkbox-ui">
                      <input
                        type="checkbox"
                        id={"order"}
                        name="checkboxAll"
                        checked={this.state.allSelected}
                        onChange={(e) => this.handleCheckbox(e, "allCheck")}
                      />
                      <label
                        htmlFor={"order"}
                        className="mr-0 custom-box"
                      ></label>
                    </div>
                  </div>
                  <div className="controller-2">
                      <Dropdown
                        alignRight={false}
                        drop="down"
                        className="analysis-card-dropdwn custom-my-radio user_drop_options"
                      >
                        <Dropdown.Toggle
                          variant="sucess"
                          id="dropdown-basic"
                          className="custom-angle-down"
                        >
                          <img src="images/angle-down.png" alt="arrow" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {tab === "approved" ? (
                            <Dropdown.Item
                              to="#/action-2"
                              className="f-20 flex-container-inner"
                              onClick={() =>
                                this.handleSortInvoiceLists("approvalDate")
                              }
                            >
                              <div
                                className="custom-control custom-radio flex-container-inner"
                              >
                                <input
                                  type="radio"
                                  className="custom-control-input flex-container-inner-input"
                                  id="approvalDate"
                                  name="approvalDate"
                                  onChange={() => {}}
                                  checked={
                                    this.state.sortFilterInvc === "approvalDate"
                                  }
                                />
                                <label
                                  className="custom-control-label flex-container-inner-input"
                                  htmlFor="approvalDate"
                                >
                                  Approval Date
                                </label>
                                {
                                  this.state.sortFilterInvc === "approvalDate" ?
                                    (
                                      this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                    ) : ("")
                                }
                              </div>
                            </Dropdown.Item>
                          ) : (
                            ""
                          )}
                          <Dropdown.Item
                            to="#/action-2"
                            className="f-20 flex-container-inner"
                            onClick={() =>
                              this.handleSortInvoiceLists("supplierName")
                            }
                          >
                            <div
                              className="custom-control custom-radio flex-container-inner"
                            >
                              <input
                                type="radio"
                                className="custom-control-input flex-container-inner-input"
                                id="supplierName"
                                name="supplierName"
                                onChange={() => {}}
                                checked={
                                  this.state.sortFilterInvc === "supplierName"
                                }
                              />
                              <label
                                className="custom-control-label flex-container-inner-input"
                                htmlFor="supplierName"
                              >
                                Supplier
                              </label>
                              {
                                this.state.sortFilterInvc === "supplierName" ?
                                  (
                                    this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                  ) : ("")
                              }
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item
                            to="#/action-2"
                            className="f-20 flex-container-inner"
                            onClick={() =>
                              this.handleSortInvoiceLists("invoiceNumber")
                            }
                          >
                            <div

                              className="custom-control custom-radio flex-container-inner"
                            >
                              <input
                                type="radio"
                                className="custom-control-input flex-container-inner-input"
                                id="invoiceNumber"
                                name="invoiceNumber"
                                onChange={() => {}}
                                checked={
                                  this.state.sortFilterInvc === "invoiceNumber"
                                }
                              />
                              <label
                                className="custom-control-label flex-container-inner-input"
                                htmlFor="invoiceNumber"
                              >
                                Invoice Number
                              </label>
                              {
                                this.state.sortFilterInvc === "invoiceNumber" ?
                                  (
                                    this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                  ) : ("")
                              }
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item
                            to="#/action-2"
                            className="f-20 flex-container-inner"
                            onClick={() => this.handleSortInvoiceLists("date")}
                          >
                            <div

                              className="custom-control custom-radio flex-container-inner"
                            >
                              <input
                                type="radio"
                                className="custom-control-input flex-container-inner-input"
                                id="date"
                                name="date"
                                onChange={() => {}}
                                checked={this.state.sortFilterInvc === "date"}
                              />
                              <label
                                className="custom-control-label flex-container-inner-input"
                                htmlFor="date"
                              >
                                Date
                              </label>
                              {
                                this.state.sortFilterInvc === "date" ?
                                  (
                                    this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                  ) : ("")
                              }
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item
                            to="#/action-2"
                            className="f-20 flex-container-inner"
                            onClick={() =>
                              this.handleSortInvoiceLists("amount")
                            }
                          >
                            <div
                              className="custom-control custom-radio flex-container-inner"
                            >
                              <input
                                type="radio"
                                className="custom-control-input flex-container-inner-input"
                                id="amount"
                                name="amount"
                                onChange={() => {}}
                                checked={this.state.sortFilterInvc === "amount"}
                              />
                              <label
                                className="custom-control-label flex-container-inner-input"
                                htmlFor="amount"
                              >
                                Amount
                              </label>
                              {
                                this.state.sortFilterInvc === "amount" ?
                                  (
                                    this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                  ) : ("")
                              }
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item
                            to="#/action-2"
                            className="f-20 flex-container-inner"
                            onClick={() =>
                              this.handleSortInvoiceLists("userName")
                            }
                          >
                            <div

                              className="custom-control custom-radio flex-container-inner"
                            >
                              <input
                                type="radio"
                                className="custom-control-input flex-container-inner-input"
                                id="userName"
                                name="userName"
                                onChange={() => {}}
                                checked={this.state.sortFilterInvc === "userName"}
                              />
                              <label
                                className="custom-control-label flex-container-inner-input"
                                htmlFor="userName"
                              >
                                Operator
                              </label>
                              {
                                this.state.sortFilterInvc === "userName" ?
                                  (
                                    this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                  ) : ("")
                              }
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item
                            to="#/action-2"
                            className="f-20 flex-container-inner"
                            onClick={() =>
                              this.handleSortInvoiceLists("approver")
                            }
                          >
                            <div
                              className="custom-control custom-radio flex-container-inner"
                            >
                              <input
                                type="radio"
                                className="custom-control-input flex-container-inner-input"
                                id="approver"
                                name="approver"
                                onChange={() => {}}
                                checked={this.state.sortFilterInvc === "approver"}
                              />
                              <label
                                className="custom-control-label flex-container-inner-input"
                                htmlFor="approver"
                              >
                                Approver
                              </label>
                              {
                                this.state.sortFilterInvc === "approver" ?
                                  (
                                    this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                  ) : ("")
                              }
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item
                            to="#/action-2"
                            className="f-20 flex-container-inner"
                            onClick={() => this.handleSortInvoiceLists("tran")}
                          >
                            <div
                              className="custom-control custom-radio flex-container-inner"
                            >
                              <input
                                type="radio"
                                className="custom-control-input flex-container-inner-input"
                                id="tran"
                                name="tran"
                                onChange={() => {}}
                                checked={this.state.sortFilterInvc === "tran"}
                              />
                              <label
                                className="custom-control-label flex-container-inner-input"
                                htmlFor="tran"
                              >
                                Transaction
                              </label>
                              {
                                this.state.sortFilterInvc === "tran" ?
                                  (
                                    this.state.order === "Descend" ? <i className="fa fa-angle-up ml-2 mt-1"></i> : <i className="fa fa-angle-down ml-2 mt-1"></i>
                                  ) : ("")
                              }
                            </div>
                          </Dropdown.Item>
                          {usePageLoading === "y" ? (
                            <div className="custom-control custom-radio cutm-pr-right">
                              <div className="settings_display_row flex__wrapper-inner">
                                <label className="labelwrapper__iner lable-inner-wrapper">
                                  Display Rows Per Page
                                </label>
                                <input
                                  className="form-control input__wrapper--inner "
                                  type="number"
                                  min="1"
                                  defaultValue={this.state.displayRecords}
                                  onBlur={this.handleRowsSetting}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </Dropdown.Menu>
                      </Dropdown>{" "}
                  </div>
                  <div className="controller-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text width-45" id="basic-addon1">
                        <img
                          src="images/search-icon.png"
                          className="mx-auto "
                          alt="search-icon"
                        />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control height-38"
                      placeholder="What are you looking for"
                      aria-label="What are you looking for"
                      aria-describedby="basic-addon1"
                      name="POListSearch"
                      id="POListSearchId"
                      value={this.state.POListSearch}
                      onChange={(e) =>
                        usePageLoading === "y"
                          ? this.handleChangeNewInvoiceListSearch(e)
                          : this.handleChangeInvoiceListSearch(e)
                      }
                      onKeyDown={(e) =>
                        usePageLoading === "y"
                          ? this.onNewInvoiceListSearch(e)
                          : this.onInvoiceListSearch(e)
                      }
                    />
                  </div>
                  <div className="controller-4">
                    <img
                      src="images/refresh.png"
                      className="cursorPointer"
                      alt="search-icon "
                      onClick={()=> this.refreshTalliesAndList()}
                    />
                  </div>
                </div>
              </div>

              <ul
                className={
                  Number(this.state.totalPages) !== 0
                    ? usePageLoading === "y"
                      ? this.getClassName()
                      : "suppliers_list"
                    : "suppliers_list"
                }
              >
                {getInvoicesList.map((l, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        l.teamInvoice === "Y"
                          ? getInvoicesList[i + 1] &&
                            getInvoicesList[i + 1].teamInvoice &&
                            getInvoicesList[i + 1].teamInvoice === "Y"
                            ? "teamOrdersBg teamOrdersBorder2 cursorPointer"
                            : "teamOrdersBg teamOrdersBorder cursorPointer"
                          : activeInvoice === l.id
                          ? "active cursorPointer"
                          : "cursorPointer"
                      }
                      id={l.id}
                    >
                      <div className="row">
                        <div className="col-auto mb-2 pr-0">
                          <div className="form-group remember_check">
                            <input
                              type="checkbox"
                              id={"invoice" + i}
                              checked={l.checked}
                              name="checkbox"
                              onChange={(e) => this.handleCheckbox(e, l)}
                            />
                            <label
                              htmlFor={"invoice" + i}
                              className="mr-0"
                            ></label>
                          </div>
                        </div>
                        <div
                          onClick={() => this.getInvoice(l)}
                          className="col pl-0"
                        >
                          <div className="invioce_data pr-sm-3">
                            <h4>{l.supplierName}</h4>
                            <div className="row">
                              <div className="col data-i">
                                <p> {l.invoiceNumber}</p>
                              </div>
                              <div className="col-auto data-i">
                                <p>
                                  {l.date ? l.date.trim() : ""}

                                  {/* {moment.unix(l.date).format("DD-MMM-YYYY")} */}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col data-i">
                                <p>
                                  <b>Tax:</b> {l.taxAmount}
                                </p>
                              </div>
                              <div className="col-auto data-i">
                                <p>
                                  <b>Due:</b>{" "}
                                  {l.dueDate ? l.dueDate.trim() : ""}
                                  {/* {moment.unix(l.dueDate).format("DD-MMM-YYYY")} */}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col data-i">
                                <p>
                                  <b>Total:</b> {l.amount}
                                </p>
                              </div>
                              <div className="col-auto data-i">
                                <div className="text-center cursorPointer">
                                  <p onClick={() => this.handleMoreDetails(l)}>
                                    <Link className="more-details-color" to="#">
                                      more details
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
              {Number(this.state.totalPages) !== 0 ? (
                usePageLoading === "y" ? (
                  <div className="pagination__list-custom">
                    <p className="mb__zero pt-top-container">
                      Showing {this.state.pageStart} to {this.state.pageEnd} of{" "}
                      {this.state.totalInvoices} entries
                    </p>
                    <ReactPaginate
                      tabIndex="-1"
                      previousLabel={"prev"}
                      pageLinkClassName={"pagination__list-custom-link   "}
                      pageClassName={"pagination__list-custom-li "}
                      activeLinkClassName={"pagination__tab-custm"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={
                        "pagination cursorPointer align-center-container"
                      }
                      activeClassName={"active"}
                      forcePage={parseInt(this.state.page) - 1}
                    />
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </aside>
            {/* {/ end /} */}

            <section id="contents" className="supplier pr-0 pt-0">
              <div className="body_content ordermain-padi body__invoice--top">
                <div className="container-fluid pl-0 ">
                  <div className="main_wrapper ">
                    <div className="row d-flex pl-15">
                      <div className="col-12 w-100 order-tabs p-md-0">
                        {/* invoice Tallies */}
                        <div className="nav_tav_ul">
                          <ul className="nav nav-tabs">
                            {this.state.invoiceTallies.map((t, i) => {
                              return (
                                <li
                                  key={i}
                                  className="nav-item cursorPointer"
                                  onClick={() =>
                                    this.handleInvoiceTallies(t.type, true)
                                  }
                                >
                                  <a
                                    className={
                                      this.state.activeInvoiceTallis === t.id
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                    // data-toggle="tab"
                                    // href={"#" + `${t.type}`}
                                  >
                                    {t.type}{" "}
                                    <span className="stats">{t.count}</span>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="bg-gry content__elem--style mm_top_nav">
                          <div className="w-100 float-left mm_lr_pad  ">
                            <div className="mm_tab_left order_left_icons ">
                              <div className="tab-content">
                                {tab === "draft" && (
                                  <div className="tab-pane container active">
                                    <ul>
                                      <li
                                        onClick={this.addNewInvoice}
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/add.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> New </Link>{" "}
                                      </li>
                                      <li
                                        onClick={() =>
                                          this.state.getInvoicesList.length > 0
                                            ? this.draftEditInvoice()
                                            : () => {}
                                        }
                                        // onClick={()=> this.openModal('openInvoiceDetailModal')}

                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/pencill.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Edit </Link>{" "}
                                      </li>
                                      <li
                                        onClick={() =>
                                          this.state.getInvoicesList.length > 0
                                            ? this.openModal("openDeleteModal")
                                            : () => {}
                                        }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/delete.svg"
                                          className="invoice-delete-icon img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Delete </Link>
                                      </li>

                                      <li
                                        className="cursorPointer"
                                        onClick={() =>
                                          this.state.getInvoicesList.length > 0
                                            ? this.sendForApprovalInvoice()
                                            : () => {}
                                        }
                                      >
                                        <img
                                          src="images/send.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Send </Link>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {tab === "approve" && (
                                  <div
                                    className={
                                      this.state.getInvoicesList.length === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      <li
                                        className="cursorPointer"
                                        onClick={this.approveInvoice}
                                      >
                                        <img
                                          src="images/tick.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Approve </Link>
                                      </li>
                                      <li
                                        onClick={this.holdInvoice}
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/move.png"
                                          className=" img-fluid"
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Hold </Link>
                                      </li>
                                      <li
                                        className="cursorPointer"
                                        onClick={() =>
                                          this.openModal("openDeclineModal")
                                        }
                                      >
                                        <img
                                          src="images/decline.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Decline </Link>
                                      </li>
                                      <li
                                        className="cursorPointer"
                                        onClick={this.openInvoiceDetailModal}
                                      >
                                        <img
                                          src="images/pencill.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Edit </Link>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {tab === "declined" && (
                                  <div
                                    className={
                                      this.state.getInvoicesList.length === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      {((userType &&
                                        userType.toLowerCase() ===
                                          "approver") ||
                                        (userType &&
                                          userType.toLowerCase() ===
                                            "approver")) && (
                                        <li
                                          className="cursorPointer"
                                          onClick={this.approveInvoice}
                                        >
                                          <img
                                            src="images/tick.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Approve </Link>
                                        </li>
                                      )}
                                    </ul>

                                    <ul>
                                      {((userType &&
                                        userType.toLowerCase() ===
                                          "operator") ||
                                        (userType &&
                                          userType.toLowerCase() ===
                                            "op/approver")) && (
                                        <li
                                          className="cursorPointer"
                                          onClick={this.moveInvoice}
                                        >
                                          <img
                                            src="images/move.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Move </Link>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}

                                {tab === "hold" && (
                                  <div
                                    className={
                                      this.state.getInvoicesList.length === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      <li
                                        className="cursorPointer"
                                        onClick={this.approveInvoice}
                                      >
                                        <img
                                          src="images/tick.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Approve </Link>
                                      </li>
                                      <li
                                        className="cursorPointer"
                                        onClick={() =>
                                          this.openModal("openDeclineModal")
                                        }
                                      >
                                        <img
                                          src="images/decline.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Decline </Link>
                                      </li>
                                      <li
                                        className="cursorPointer"
                                        onClick={this.openInvoiceDetailModal}
                                      >
                                        {" "}
                                        <img
                                          src="images/pencill.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Edit </Link>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {tab === "pending" && (
                                  <div
                                    className={
                                      this.state.getInvoicesList.length === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      {((userType && userType === "operator") ||
                                        (userType &&
                                          userType === "op/approver")) && (
                                        <li
                                          className="cursorPointer"
                                          onClick={this.onClickMoveInvoice}
                                        >
                                          <img
                                            src="images/move.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Move </Link>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}
                                {tab === "approved" && (
                                  <div
                                    className={
                                      this.state.getInvoicesList.length === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      <li></li>
                                    </ul>
                                  </div>
                                )}
                                {tab === "all" && (
                                  <div
                                    className={
                                      this.state.getInvoicesList.length === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      <li></li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="mm_tab_center order_right_icons">
                              <div className="slider-panel">
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.zoomOut}
                                    src="images/minus.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.zoomIn}
                                    src="images/add.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Select
                                  className="width-selector"
                                  value={this.state.dropdownZoomingValue}
                                  classNamePrefix="custon_select-selector-inner"
                                  options={options}
                                  onChange={this.handleDropdownZooming}
                                  theme={(theme) => ({
                                    ...theme,
                                    border: 0,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#f2f2f2",
                                      primary: "#f2f2f2",
                                    },
                                  })}
                                />
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.handleHorizontalCross}
                                    src="images/fulls.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                    id="full_screen"
                                  />{" "}
                                </Link>
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.handleHorizontalArrow}
                                    src="images/twoarow.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                    id="expand"
                                  />{" "}
                                </Link>
                                <span
                                  onClick={this.handlePDFRotate}
                                  className="cursorPointer"
                                >
                                  <img
                                    src="images/undo.png"
                                    className=" img-fluid float-left undo-img"
                                    alt="user"
                                  />
                                </span>

                                <Link
                                  to="#"
                                  className="zom-img float-right ml-md-5 mr-0 pl-2 pr-2 more-d mt-0"
                                >
                                  <img
                                    src="images/more.png"
                                    className=" img-fluid"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Link
                                  to="#"
                                  className="zom-img float-right mt-0"
                                  onClick={this.moveToNextInvoice}
                                >
                                  <img
                                    src="images/arow-r.png"
                                    className=" img-fluid lr-arrow-up"
                                    alt="user"
                                    data-slide="next"
                                  />{" "}
                                </Link>
                                <Link
                                  to="#"
                                  className="zom-img float-right mtop-1"
                                  onClick={this.moveToPrevInvoice}
                                >
                                  <img
                                    src="images/arow-l.png"
                                    className=" img-fluid lr-arrow-up"
                                    alt="user"
                                    data-slide="prev"
                                  />{" "}
                                </Link>

                                <div className="side-attachments-2 height-2 mm_invoice_sidebar2 aside__right--height">
                                  <div
                                    onClick={this.regenerateSignatures}
                                    className="main-sec-attach main-bg"
                                  >
                                    Regenerate
                                  </div>
                                  {
                                    this.state.showInvoiceTallisTabPane === "Approved" ?
                                      <div
                                        onClick={() => this.resendEmail()}
                                        className="main-sec-attach main-bg"
                                      >
                                        Resend Approved Email
                                      </div>
                                      : null
                                  }
                                 {userType === 'admin' && <div
                                    onClick={() => this.setState({showTransferRecordsModal: true})}
                                    className="main-sec-attach main-bg"
                                  >
                                    Transfer Records
                                  </div>}
                                  <div
                                    onClick={this.downloadPreview}
                                    className="main-sec-attach main-bg"
                                  >
                                    Download Copy
                                    <img
                                      src="images/downlod.png"
                                      className=" img-fluid float-right fa"
                                      alt="user"
                                    />
                                  </div>


                                  <div className="main-sec-attach main-bg" onClick={(e)=> this.collapseIconsChanged('attachment', !this.state.collapseIcon.attachment )}>
                                    <span
                                      className="invoice-inherit"
                                      data-toggle="collapse"
                                      data-target="#download_timecard_copies"
                                    >
                                       <span  className={ this.state.collapseIcon.attachment ? "fa fa-angle-up float-left mr-2 sideBarAccord1 " : "fa fa-angle-down float-left mr-2 sideBarAccord1" }></span>
                                      Download Attachments
                                      <div className="float-right">
                                        <img
                                          onClick={this.downloadSelectedAttachments}
                                          src="images/downlod.png"
                                          className=" img-fluid  fa"
                                          alt="user"
                                        />
                                      </div>
                                    </span>
                                  </div>
                                  <div
                                    className="collapse"
                                    id="download_timecard_copies"
                                  >
                                    <div className="pl-2 mb-3">
                                      <div className="form-group remember_check d-flex">
                                        <div className="checkSide input-wrapper-disabled">
                                          <input
                                            type="checkbox"
                                            id={"includeTimeSheets"}
                                            name={"includeTimeSheets"}
                                            disabled
                                            checked={
                                              this.state.downloadAttachmentsOptions.includeAll
                                            }
                                            onChange={(e) =>
                                             this.setState({downloadAttachmentsOptions: {includeAll: e.target.checked}})
                                            }
                                          />
                                          <label
                                            htmlFor={"includeTimeSheets"}
                                          ></label>
                                          <span className="text-mar">
                                            Include All
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                  <div className="main-sec-attach main-bg mb-0">
                                    <span
                                      className="accordian__text bank_details"
                                      data-toggle="collapse"
                                      data-target="#bank_details "
                                      onClick={() => { this.getSupplier(); }}
                                    >
                                      <span className="fa fa-angle-down float-left mr-2 sideBarAccord1"></span>
                                      Bank Details
                                    </span>
                                  </div>
                                  <div
                                    className="collapse"
                                    id="bank_details"
                                  >
                                    <div className="">
                                      <div className="form-group remember_check d-flex pt-0">
                                        <div className="ml-33 pt-1">
                                          {
                                            this.displayBankDetail(bankDetails?.paymentType, 'paymentType')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.accountName, 'accountName')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.bank, 'bank')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.sort, 'sort')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.accountNo, 'accountNo')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.suffix, 'suffix')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.iban, 'iban')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.bankReference, 'bankReference')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.currencyCode, 'currencyCode')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.swift, 'swift')
                                          }
                                          {
                                            this.displayBankDetail(bankDetails?.countryCode, 'countryCode')
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="main-sec-attach main-bg" onClick={(e)=> this.collapseIconsChanged('approvals', !this.state.collapseIcon.approvals )}>
                                    <span
                                      className="approvals-inherit "
                                      data-toggle="collapse"
                                      data-target="#Approvals_invoice1 "
                                    >
                                      <span  className={ this.state.collapseIcon.approvals ? "fa fa-angle-up float-left mr-2 sideBarAccord1 " : "fa fa-angle-down float-left mr-2 sideBarAccord1" }></span>
                                      Approvals
                                    </span>
                                  </div>
                                  <div
                                    className="collapse"
                                    id="Approvals_invoice1"
                                  >
                                    {this.state.approvalsGroups.map((a, i) => {
                                      return (
                                        <div key={i} className="pl-2 mb-3">
                                          <div className="form-group remember_check d-flex">
                                            <div className="checkSide">
                                              <input
                                                type="checkbox"
                                                id={i + "invoice"}
                                                name={a.groupName}
                                                checked={a.checked}
                                                onChange={(e) =>
                                                  this.handleApprovalsFilters(
                                                    e,
                                                    a
                                                  )
                                                }
                                              />
                                              <label htmlFor={i + "invoice"}>
                                                {" "}
                                              </label>
                                            </div>
                                            <span className="text-mar">
                                              {a.groupName}{" "}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>

                                  <div className="main-sec-attach main-bg" onClick={(e)=> this.collapseIconsChanged('activity', !this.state.collapseIcon.activity )}>
                                    <span
                                      className="fa fa-angle-down float-left mr-2 sideBarAccord"
                                      data-toggle="collapse"
                                      data-target="#Activity_invoice"
                                      onClick={(e) => { this.getInvoiceActivity(this.state.selectedGuid, e); }}
                                    ></span>
                                    <span
                                      className="name_attached font-weight-bold"
                                      onClick={() =>
                                        this.getInvoiceActivity(this.state.selectedGuid)
                                      }
                                    >
                                      Activity
                                      <span className="fa fa-angle-right"></span>
                                    </span>
                                  </div>
                                  <div
                                    className="collapse"
                                    id="Activity_invoice"
                                  >
                                    {this.state.invoiceActivity.map((a, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="main-sec-attach1"
                                        >
                                          {a.description}
                                          <p className="gry-clr mb-0">
                                            {a.activityDate} {a.activityTime}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div className="main-sec-attach main-bg" onClick={(e)=> this.collapseIconsChanged('export', !this.state.collapseIcon.export )}>
                                    <span
                                      className="export_crd"
                                      data-toggle="collapse"
                                      data-target="#export"
                                    >
                                       <span  className={ this.state.collapseIcon.export ? "fa fa-angle-up float-left mr-2 sideBarAccord1 " : "fa fa-angle-down float-left mr-2 sideBarAccord1" }></span>
                                      Export
                                    </span>
                                  </div>
                                  <div className="collapse" id="export">
                                    {["EXCEL", "TPH", "TAX INVOICE"].map(
                                      (op, i) => {
                                        return (
                                          <div
                                            key={i}
                                            className="pl-2 mb-3"
                                            onClick={() =>
                                              this.hanldeExport(op)
                                            }
                                          >
                                            <div className="form-group remember_check d-flex">
                                              <span className="text-mar cursorPointer ml-38">
                                                {op}
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                  <div
                                    onClick={() =>
                                      this.openModal("openReportModal")
                                    }
                                    className="main-sec-attach main-bg"
                                  >
                                    Reports
                                  </div>
                                  <div
                                    className="main-sec-attach main-bg"
                                    onClick={this.balanceTax}
                                  >
                                    Tax Balance
                                  </div>
                                  {tab === "draft" ? (
                                    <div
                                      onClick={() =>
                                        this.openModal("openImportModal")
                                      }
                                      className="main-sec-attach main-bg"
                                    >
                                      Import
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div
                                    onClick={this.openMoveBatchPopup}
                                    className="main-sec-attach main-bg"
                                  >
                                    Move
                                  </div>
                                  <div
                                    onClick={this.openPostModal}
                                    className="main-sec-attach main-bg"
                                  >
                                    Post
                                  </div>
                                  {/* batch list start here  */}
                                  <div className="main-sec-attach main-bg" onClick={(e)=> this.collapseIconsChanged('batchList', !this.state.collapseIcon.batchList )}>
                                    <span
                                      className="batchList-inherit"
                                      data-toggle="collapse"
                                      data-target="#batchlist"
                                    >
                                      <span  className={ this.state.collapseIcon.batchList ? "fa fa-angle-up float-left mr-2 sideBarAccord1 " : "fa fa-angle-down float-left mr-2 sideBarAccord1" }></span>
                                      Batch List
                                    </span>
                                  </div>
                                  <div className="collapse" id="batchlist">
                                    <div className="pl-2 mb-3">
                                      <div className="text-right pb-2 pr-4 	">
                                        <span
                                          className="cursorPointer mr-3 d-none"
                                          href="#"
                                          onClick={this.insertBatch}
                                        >
                                          <img
                                            src="images/add.png"
                                            className="img-fluid "
                                            alt="user"
                                          />
                                        </span>
                                        <span
                                          className="cursorPointer d-none"
                                          onClick={this.deleteBatch}
                                        >
                                          <img
                                            src="images/delete.svg"
                                            className="invoice-delete-icon img-fluid "
                                            alt="user"
                                          />
                                        </span>
                                      </div>
                                      <table className="table table-bordered mb-0 order-collapse-table batch-list-table">
                                        <tbody>
                                          <tr>
                                            <th></th>
                                            <th>Description</th>
                                            <th>Batch</th>
                                            <th></th>
                                          </tr>
                                          {clonedBatchList.map((b, i) => {
                                            return (
                                              <tr>
                                                <td>
                                                  <div className="form-group remember_check">
                                                    <input
                                                      type="checkbox"
                                                      id={"batch" + i}
                                                      name="batch_checked"
                                                      checked={
                                                        b.checked
                                                      }
                                                      onChange={(e) =>
                                                        this.handleBatchCheckbox(
                                                          e,
                                                          b.batchNo
                                                        )
                                                      }
                                                    />
                                                    <label
                                                      htmlFor={"batch" + i}
                                                    ></label>
                                                  </div>
                                                </td>
                                                <td>
                                                  <input
                                                    className="border-0"
                                                    name="description"
                                                    autoComplete="off"
                                                    value={b.description}
                                                  />
                                                </td>
                                                <td>
                                                  {b.insertBatch ? (
                                                    <input
                                                      className="border-0"
                                                      name="batchNo"
                                                      value={b.batchNo}
                                                      onBlur={(e) =>
                                                        this.addUpdateBatch(
                                                          e,
                                                          b,
                                                          i
                                                        )
                                                      }
                                                      onChange={(e) =>
                                                        this.handleChangeBatchFields(
                                                          e,
                                                          b,
                                                          i
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    b.batchNo
                                                  )}
                                                </td>
                                                <td></td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  {/* end  Batch list  */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="img-section-t col-12 pl-0">
                      {/* <img src="images/image6.png" className=" img-fluid" alt="user" />  */}

                      <div
                        className={
                          this.state.toggleRightSidebar
                            ? " mm_ordr1 order_pdf_new invo_margin"
                            : " mm_ordr1 order_pdf_expand1 m_auto"
                        }
                        id="overload_image--invoice"
                      >
                        <div
                          id="maped_image"
                          className="order_pfd over_auto_remove"
                          style={{ background: "#fff" }}
                        >
                          {this.state.getInvoicesList.length > 0 && (
                            <div
                              id="demo"
                              className={
                                this.state.toggleRightSidebar
                                  ? " carousel slide invoice_carousel mm_invoice_div over_auto_remove invoice-carowsel-new"
                                  : " carousel slide invoice_carousel invoice-carowsel-new "
                              }
                              // className="carousel slide invoice_carousel mm_invoice_div"
                              // data-ride="carousel"
                              data-interval={false}
                            >
                              <ul className="carousel-indicators">
                                <li
                                  data-target="#demo"
                                  data-slide-to="0"
                                  className="active"
                                ></li>
                                <li data-target="#demo" data-slide-to="1"></li>
                                <li data-target="#demo" data-slide-to="2"></li>
                              </ul>
                              <div className="carousel-inner">
                                {this.state.previewList.length > 0
                                  ? this.state.previewList.map((p, i) => {
                                    const currentIndex = this.state.currentAttachmentIndex;
                                    const currentFile = this.state.previewList[currentIndex]?.file;
                                      return (
                                        <div
                                          className={
                                            i === 0
                                              ? "carousel-item active "
                                              : "carousel-item "
                                          }
                                          id={i}
                                        >
                                          <div className="text-center">
                                            <div className="invoice_pdf_canvas invoice_pdf_new pdf--buttons pdf__height--content invoice-pdf-height">
                                              {currentFile ? (
                                                <>
                                                  <Document
                                                    file={
                                                      "data:application/pdf;base64," +
                                                      currentFile
                                                    }
                                                    onLoadSuccess={(data) =>
                                                      this.onDocumentLoadSuccess(
                                                        data,
                                                        i
                                                      )
                                                    }
                                                    rotate={this.state.rotate}
                                                  >
                                                    <Page
                                                      pageNumber={
                                                        this.state.pageNumber
                                                      }
                                                      scale={this.state.scaling}
                                                      height="372"
                                                      onLoadSuccess={
                                                        this.onLoadSuccessPage
                                                      }
                                                    />
                                                  </Document>
                                                  <div className="page-controls">
                                                    <button
                                                      type="button"
                                                      disabled=""
                                                      onClick={
                                                        this.goToPrevPage
                                                      }
                                                    >
                                                      ‹
                                                    </button>
                                                    <span>
                                                      {this.state.pageNumber} of{" "}
                                                      {this.state.numPages}
                                                    </span>{" "}
                                                    <button
                                                      type="button"
                                                      onClick={
                                                        this.goToNextPage
                                                      }
                                                    >
                                                      ›
                                                    </button>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : "No Preview Found"}

                                {this.state.previewableAttachments.length > 1 && (
                                  <>
                                    <a                                                                                                                                          
                                      className="carousel-control-prev"
                                      href="#demo"
                                      data-slide="prev"
                                      onClick={() => this.onSlideChange('prev')}
                                    >
                                      <i>
                                        {" "}
                                        <span className="carousel-control-prev-icon"></span>
                                      </i>
                                    </a>
                                    <a
                                      className="carousel-control-next"
                                      href="#demo"
                                      data-slide="next"
                                      onClick={() => this.onSlideChange('next')}
                                    >
                                      <i>
                                        {" "}
                                        <span className="carousel-control-next-icon"></span>
                                      </i>
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="side-attachments mm_invoice_sidebar mm_invoice_sidebar_right aside__right--height">
                        <div
                          className="cus-arro-div"
                          onClick={this.handleRightSidebar}
                        >
                          <img
                            src="images/arrow-r.png"
                            className=" img-fluid cus-arro-r"
                            alt="user"
                          />
                        </div>
                        <div className="side-attack">
                          <div className="main-sec-attach main-bg">
                            {/*Invoice Attachments */}
                            <span
                              className="fa fa-angle-up float-left mr-2 sideBarAccord"
                              data-toggle="collapse"
                              data-target="#Attachments_invoice"
                            ></span>
                            <span
                              className="name_attached"
                              onClick={() =>
                                this.openModal("openAttachmentsModal")
                              }
                            >
                              Attachments
                              <span className="ml-3 font-weight-bold">
                                {this.state.attachments.length}
                              </span>
                              <span className="fa fa-angle-right"></span>
                              <p className="float-right mr-3">
                                <img
                                  src="images/add.png"
                                  className=" img-fluid sidebarr_plus "
                                  alt="user"
                                />
                              </p>
                            </span>
                          </div>
                          <div
                            className="collapse show"
                            id="Attachments_invoice"
                          >
                            {this.state.attachments.map((a, i) => {
                              return (
                                <div
                                  onClick={() =>
                                    this.getAttachment(
                                      a.recordID,
                                      a.contentType,
                                      a.fileName
                                    )
                                  }
                                  key={i}
                                  className="main-sec-attach"
                                >
                                  {a.fileName}{" "}
                                  <span className="fa fa-angle-right"></span>
                                </div>
                              );
                            })}
                          </div>
                          {/* side menue Approvers / Approvals */}
                          <div className="main-sec-attach main-bg">
                            <span
                              className="invoice-inherit"
                              data-toggle="collapse"
                              data-target="#Approvals_invoice"
                            >
                              <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span>
                              Approvals
                            </span>
                          </div>

                          <div className="collapse show" id="Approvals_invoice">
                            {this.state.approvalGroup.value &&
                              this.state.approvalGroup.value.trim() && (
                                <div
                                  className={
                                    approverNextPrevCheck
                                      ? "main-sec-mid"
                                      : "main-sec-mid1"
                                  }
                                >
                                  {approverNextPrevCheck && (
                                    <button
                                      // onClick={sendEmail}
                                      type="button"
                                      className="btn-save1 add_comment_btns1"
                                      onClick={() =>
                                        this.openModal(
                                          "openCheckApprovalModal",
                                          "Prev"
                                        )
                                      }
                                    >
                                      <span></span>
                                      Previous
                                    </button>
                                  )}
                                  <small>
                                    {this.state.approvalGroup.value}
                                  </small>
                                  {approverNextPrevCheck && (
                                    <button
                                      // onClick={sendEmail}
                                      type="button"
                                      className="btn-save1 add_comment_btns"
                                      onClick={() =>
                                        this.openModal(
                                          "openCheckApprovalModal",
                                          "Next"
                                        )
                                      }
                                    >
                                      <span></span>
                                      Next
                                    </button>
                                  )}
                                </div>
                              )}
                            {this.state.approvers.map((a, i) => {
                              return (
                                <div
                                  key={i}
                                  className="main-sec-attach cus-check"
                                >
                                  <div className="form-group remember_check">
                                    {a.status === "Approved" ? (
                                      <input type="checkbox" id={i} checked />
                                    ) : (
                                      ""
                                    )}
                                    {a.status === "Declined" ? (
                                      <i
                                        className="fa fa-times circle-check1 float-left ml-1"
                                        aria-hidden="true"
                                      ></i>
                                    ) : a.status === "Current" ? (
                                      <i
                                        className="fa fa-circle-thin circle-check float-left ml-1"
                                        aria-hidden="true"
                                        style={{ marginTop: "3px" }}
                                      ></i>
                                    ) : (
                                      ""
                                    )}
                                    <label htmlFor={i}>
                                      {" "}
                                      <span
                                        title={a.approverName}
                                        className={`${
                                          a.status === "Declined"
                                            ? "order-right-color2 selected mm_lcapp"
                                            : a.status === "Current"
                                            ? "order-right-color1 selected mm_lcapp"
                                            : "text-mar"
                                        } approver-name-lbl 
                                            ${
                                              a.status === "Current" ||
                                              a.status === "Declined"
                                                ? "approver-name-lbl-wide"
                                                : ""
                                            }
                                            ${
                                              userType === "admin" &&
                                              a.status === "Current"
                                                ? "approver-name-lbl-medium"
                                                : ""
                                            }`}
                                      >
                                        {a.approverName}
                                      </span>
                                      {a.status === "Declined" ? (
                                        <span className="current-approver mm_approre1">
                                          {" "}
                                          (user declined)
                                        </span>
                                      ) : a.status === "Current" ? (
                                        <span className="current-approver mm_approre1">
                                          {" "}
                                          (current approver)
                                          {userType === "admin" && (
                                            <button
                                              onClick={() => this.resendEmail()}
                                              className="btn-save2"
                                            >
                                              Send Email
                                            </button>
                                          )}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          {/* Invoice Comments */}
                          <div className="main-sec-attach main-bg">
                            <span
                              className="fa fa-angle-up float-left mr-2 sideBarAccord"
                              data-toggle="collapse"
                              data-target="#Comments_invoice"
                            ></span>
                            <span
                              className="name_attached"
                              onClick={() =>
                                this.openModal("openCommentsModal")
                              }
                            >
                              Comments
                              <span className="ml-3 font-weight-bold">
                                {this.state.comments.length}
                              </span>
                              <span className="fa fa-angle-right"></span>
                              {this.state.showInvoiceTallisTabPane ===
                                "Draft" ||
                              this.state.showInvoiceTallisTabPane ===
                                "Pending" ||
                              this.state.showInvoiceTallisTabPane ===
                                "Approve" ||
                              this.state.showInvoiceTallisTabPane === "Hold" ||
                              this.state.showInvoiceTallisTabPane ===
                                "Declined" ? (
                                <a className="float-right mr-3" href="#">
                                  <img
                                    src="images/add.png"
                                    className=" img-fluid sidebarr_plus "
                                    alt="user"
                                  />
                                </a>
                              ) : null}
                            </span>
                          </div>
                          <div className="collapse show" id="Comments_invoice">
                            {this.state.comments.map((c, i) => {
                              return (
                                <div key={i} className="main-sec-attach1">
                                  <p className="m-clr s-bold mb-0">
                                    {c.userName}
                                  </p>
                                  {c.comment}
                                  <p className="gry-clr mb-0">
                                    {c.date} {c.time}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* end */}
        </div>

        <Decline
          openDeclineModal={this.state.openDeclineModal}
          closeModal={this.closeModal}
          onDecline={this.declineInvoice}
        />
        <Attachments
          openAttachmentsModal={this.state.openAttachmentsModal}
          closeModal={this.closeModal}
          addAttachment={this.addAttachment}
          attachments={this.state.attachments}
          getAttachment={this.getAttachment}
          attachmentSize={this.state.attachmentSize}
          draft={tab === "draft" ? true : false} //to hide/show "Drag Files in or Click to Upload" box
        />

        <Comments
          openCommentsModal={this.state.openCommentsModal}
          closeModal={this.closeModal}
          comments={this.state.comments}
          addComment={this.addComment}
          tab={tab}
        />

        <ActivityInvoice
          openActivityModal={this.state.openActivityModal}
          closeModal={this.closeModal}
          activity={this.state.invoiceActivity}
        />

        <Changes
          openChangesModal={this.state.openChangesModal}
          closeModal={this.closeModal}
          changes={this.state.invoiceChanges}
        />

        <InvoiceDetail
          openInvoiceDetailModal={this.state.openInvoiceDetailModal}
          closeModal={this.closeModal}
          openModal={this.openModal}
          chartSorts={this.props.chart.getChartSorts || ""} //api response (get chart sort)
          chartCodes={this.state.chartCodesList || []} //api response (all chart codes)
          flags_api={this.props.chart.getFlags} //flags comming from get flags api
          flags={this.state.flags} //restructured flags accordings to requirements
          clonedFlags={this.state.clonedFlags} //a copy of flags
          invoiceLines={this.state.lines || []} //invoice lines of an Invoice
          subTotal={this.state.subTotal}
          flagsPrompts={
            //to show flags prompts in order detail header
            this.props.user.getDefaultValues &&
            this.props.user.getDefaultValues.flags &&
            this.props.user.getDefaultValues.flags.length > 0
              ? this.props.user.getDefaultValues.flags
              : []
          }
          getNewORUpdatedInvoiceLine={this.getNewORUpdatedInvoiceLine} //update invoice line on add/edit Invoice lines
          updateInvoiceLines={this.updateInvoiceLines}
          handleMultipleChanges={this.handleMultipleChanges} //update invocie-lines according to multiple change modal
          deleteInvoiceLine={this.deleteInvoiceLine} //to delete Invoice line
          props={this.props}
          handleCheckboxesInInvoiceDetails={
            this.handleCheckboxesInInvoiceDetails
          }
          tab={tab}
          basisOptions={this.state.basisOptions || []}
          getChartCodes={this.getChartCodes} //get chart codes function
          getChartSorts={this.getChartSorts} //get chart sorts function
          handleChangeChartCode={this.handleChangeChartCode}
          handleInLine={this.handleInLine}
          handleChangeField={this.handleChangeField}
          convertTwoDecimal={this.convertTwoDecimal}
          handleChangeFlags={this.handleChangeFlags}
          onblurCode={this.onblurCode}
          changeChartCode={this.changeChartCode}
          chartCodesList={this.state.chartCodesList}
          clonedChartCodesList={this.state.clonedChartCodesList}
          advancedList={this.state.advancedListEdit}
        />

        <InvoiceMoreDetails
          openModal={this.openModal}
          closeModal={this.closeModal}
          openInvoiceMoreDetailsModal={this.state.openInvoiceMoreDetailsModal}
          // invoiceMoreDetails={this.state.invoiceMoreDetails}
          showInvoiceTallisTabPane={showInvoiceTallisTabPane}
          updateInvoiceSummaryState={this.updateInvoiceSummaryState}
          getInvoice={this.getInvoice}
          state={this.state}
        />

        <POLogs
          openPOLogsModal={this.state.openPOLogsModal}
          openModal={this.openModal}
          closeModal={this.closeModal}
          poLog={this.state.poLines}
        />

        <Delete
          openDeleteModal={this.state.openDeleteModal}
          closeModal={this.closeModal}
          onDelete={this.deleteInvoice}
        />

        <Import
          state={this.state}
          closeModal={this.closeModal}
          page="invoice"
          onImport={this.onImport}
        />

        <Post
          openPostModal={this.state.openPostModal}
          closeModal={this.closeModal}
          postType="Invoices"
          onSave={this.postInvoice}
          locationProps={this.props}
        />
        <Report
          openReportModal={this.state.openReportModal}
          closeModal={this.closeModal}
          reportType="Invoices"
          locationProps={this.props}
        />
        <Move
          closeModal={this.closeModal}
          stateDate={this.state}
          moveBatch={this.moveBatch}
        />
        <ConfirmationModal
          openConfirmationModal={isModalOpen}
          closeModal={this.closeConfrimationModal}
          title={modalContent.title}
          body={modalContent.body}
          actionButtonName={modalContent.actionButtonName}
          onConfirmation={modalContent.onConfirm}
        />
        {userType === "admin" && (
          <CheckApproval
            openCheckApprovalModal={this.state.openCheckApprovalModal}
            closeModal={this.closeModal}
            state={this.state}
            moveToNextApprover={this.moveToNextApprover}
            moveToPrevApprover={this.moveToPrevApprover}
          />
        )}
        {
          userType === 'admin' && this.state.showTransferRecordsModal && (
            <TransferRecords
              closeModal={() => this.setState({ showTransferRecordsModal: false })}
              selectedItemCount={this.state.allSelected ? undefined : this.state.multipleSelectedGuid.length || 1}
               itemNameList={this.state.allSelected ? undefined :
                [...new Set(this.state.multipleSelectedGuid.length ? this.state.multipleSelectedGuid: [...this.state.multipleSelectedGuid, this.state.selectedGuid])].map(guid => {
                const invoice = this.state.getInvoicesList.find(invoice => invoice.guid === guid);
                return `INV#${invoice.invoiceNumber} ${invoice.supplierName}`
              }) }
              onSubmit={this.handleTransferInvoices}
            />
          )
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  invoiceData: state.invoice,
  chart: state.chart,
  supplier: state.supplier,
  setup: state.setup,
  pageData: state.pagination.pageData["invoices"],
});

export default connect(mapStateToProps, {
  getNewInvoiceList: InvoiceActions.getNewInvoiceList,
  getInvoiceTallies: InvoiceActions.getInvoiceTallies,
  getInvoicesList: InvoiceActions.getInvoicesList,
  getInvoice: InvoiceActions.getInvoice,
  getInvoiceSummary: InvoiceActions.getInvoiceSummary,
  addComment: InvoiceActions.addComment,
  addInvoiceAttachments: InvoiceActions.addInvoiceAttachments,
  getInvoiceAttachments: InvoiceActions.getInvoiceAttachments,
  approveInvoice: InvoiceActions.approveInvoice,
  declineInvoice: InvoiceActions.declineInvoice,
  deleteInvoice: InvoiceActions.deleteInvoice,
  moveInvoice: InvoiceActions.moveInvoice,
  sendForApprovalInvoice: InvoiceActions.sendForApprovalInvoice,
  updateInvoiceLines: InvoiceActions.updateInvoiceLines,
  updateApprovalGroup: InvoiceActions.updateApprovalGroup,
  regenerateSignatures: InvoiceActions.regenerateSignatures,
  postInvoice: InvoiceActions.postInvoice,
  balanceTax: InvoiceActions.balanceTax,
  exportInvoice: InvoiceActions.exportInvoice,
  exportTPH: InvoiceActions.exportTPH,
  exportTaxInvoice: InvoiceActions.exportTaxInvoice,
  moveBatch: InvoiceActions.moveBatch,
  importChqRequest: InvoiceActions.importChqRequest,
  importList: InvoiceActions.importList,
  importEPFile: InvoiceActions.importEPFile,
  moveToNextApprover: InvoiceActions.moveToNextApprover,
  moveToPrevApprover: InvoiceActions.moveToPrevApprover,
  resendEmail: InvoiceActions.resendEmail,
  transferInvoices: InvoiceActions.transferInvoices,

  clearInvoiceStates: InvoiceActions.clearInvoiceStates,
  getChartCodes: ChartActions.getChartCodes,
  getChartSorts: ChartActions.getChartSorts,
  getFlags: ChartActions.getFlags,
  clearChartStates: ChartActions.clearChartStates,
  getDefaultValues: UserActions.getDefaultValues,
  getSupplier: SupplierActions.getSupplier,
  clearSupplierStates: SupplierActions.clearSupplierStates,
  getBtachList: SetupActions.getBtachList,
  deleteBatch: SetupActions.deleteBatch,
  updateBatch: SetupActions.updateBatch,
  insertBatch: SetupActions.insertBatch,
  clearSetupStates: SetupActions.clearSetupStates,
  clearUserStates: UserActions.clearUserStates,
  clearStatesAfterLogout: UserActions.clearStatesAfterLogout,
  holdInvoice: InvoiceActions.holdInvoice,
  setLastPage: PaginationActions.setLastPageNumber,
  resetLastPage: PaginationActions.resetLastPage,
  downloadAttachments: InvoiceActions.downloadInvoiceAttachments
})(Invoice);
